import React from "react";
import ChildService from "../../components/OurServices/ChildService"
import Layout from "../../Layout/Light";

function ServicesChildPage() {
return (
    <Layout>
        <ChildService /> 
    </Layout>
        
    )
}

export default ServicesChildPage