import React from "react";
import Aboutus from "./Aboutus";
import Aboutservices from "./Aboutservices";
import Action from "./Action";
import WhatsAppbtn from "./WhatsAppbtn";
import Top from "./Top";
import NavigationBar from "./Navigation/NavigationBar";
import HeroVideo from "./Hero/HeroVideo";
const LazyPartners = React.lazy(() => import("./Partners"));
const LazyPortfolio = React.lazy(() => import("./Portfolio"));
const LazyStatistics = React.lazy(() => import("./Statistics"));
const LazyProcess = React.lazy(() => import("./Process"));
const LazyTestimonial = React.lazy(() => import("./Testimonial"));
const LazyServices = React.lazy(() => import("./Services"));
const LazyPartnerlist = React.lazy(() => import("./Partnerlist"));
const LazyResourses = React.lazy(() => import("./Resources"));
const LazyContactForm = React.lazy(() => import("./Contact"));
const LazyFooter = React.lazy(() => import("./Footer"));

function Home() {
    return ( 
    <>
            <NavigationBar/>
            <HeroVideo />
            <LazyPartners />
            <LazyPartnerlist />
            <Aboutus />
            <LazyPortfolio />
            <LazyStatistics />
            <Aboutservices />
            <LazyServices />
            <hr className="partition" />
            <LazyTestimonial />
            <LazyProcess />
            <LazyContactForm />
            <hr className="partition" />
            <LazyResourses />
            <Action />
            <LazyFooter />
            <WhatsAppbtn />
            <Top />   
    </>           
    )
}

export default Home