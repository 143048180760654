import React, { useState } from "react";
import "./NavigationBar.css";
import ToggleMenu from "./ToggleMenu";
import { Link } from "react-router-dom";
import ServicesMenu from "./ServicesMenu";
import { FaCaretDown } from "react-icons/fa";

function NavigationBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="navbar-logo">
        <a href="/" className="navbar_logo_text">
        <picture>
          <source srcSet={require("../../data/images/novela-films-logo-sm.webp")} 
          media="(max-width: 320px)" />
          <img
            alt="novela-films-company-logo"
            src={require("../../data/images/novela-films-logo-default.webp")}
            className="navbar_logo_img"
          />
        </picture>
          &nbsp;Novela Films
        </a>
      </div>
      <ul className="navbar__links">
      <li><Link className={`navbar__link ${isDropdownOpen ? "rotate-icon" : ""}`}
      onClick={handleToggleDropdown}>
          SERVICES{" "}<FaCaretDown className="caret-icon" 
           />
          </Link>
          {isDropdownOpen && (
            <ServicesMenu
              isOpen={isDropdownOpen}
              onClose={() => setIsDropdownOpen(false)}
            />
          )}
        </li>
        <li><Link to="/portfolio" className="navbar__link">PORTFOLIO</Link></li>
        <li><Link to="/clients" className="navbar__link">CLIENT REVIEWS</Link></li>
        <li><Link to="/resources" className="navbar__link">RESOURCES</Link></li>
        <li><Link to="/contact" className="navbar_button">
            <span className="navbar_button_text">GET QUOTE</span>
            <span className="navbar_button_fill"></span>
          </Link>
        </li>
        <li className="">
          <ToggleMenu />
        </li>
      </ul>
    </nav>
  );
}

export default NavigationBar;
