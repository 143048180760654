import React from "react"
import Card from "react-bootstrap/Card";


function Blogcard(props) {
    return (
        <Card className="card-b">
        <Card.Img src={props.blogIMG} alt={props.imgDETAIL} style={{width:"100%", height:"100%"}}/>
        <Card.Body>
        <Card.Title className="blogs-title" >{props.blogNAME}</Card.Title>
        <Card.Title className="blogs-title" >{props.content}</Card.Title>
        </Card.Body>
        </Card>
        
      );
    }
export default Blogcard