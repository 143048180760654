const processData = [
    {
        id: 1,
        key: 1,
        // OUR PROCESS STEP 1
        opS1Title: "Video Brief",
        opS1Text: "A clear and concise explainer video brief aligns stakeholders, ensures clarity in objectives, content, style, and messaging. It saves time, promotes efficiency, minimizes revisions, and acts as a roadmap to keep production focused on goals and target audience. It facilitates evaluation, feedback, and productive discussions for adjustments.",
        opS1Img: require("../data/images/process/video-brief.webp"),
        opS1ImgAlt: "animation explainer video brief novela films private limited",
        // OUR PROCESS STEP 2
        opS2Title: "The Kick-Off Call",
        opS2Text: "During the kick-off call, team members introduce themselves and discuss project scope, roles and responsibilities, and the communication plans. The project timeline is outlined, challenges are identified, and next steps are determined. This call establishes expectations and promotes successful collaboration, communication, and project management for the duration of the project.",
        opS2Img: require("../data/images/process/kick-off-call.webp"),
        opS2ImgAlt: "animation explainer kick off call novela films private limited",
        // OUR PROCESS STEP 3
        opS3Title: "Script",
        opS3Text: "The script ensures the message is delivered in a concise and engaging manner, utilizing storytelling techniques to captivate the audience. The script also fosters collaboration, maintains control over timing and information flow, and effectively communicates the video's intended message and goals.",
        opS3Img: require("../data/images/process/script.webp"),
        opS3ImgAlt: "animation explainer script novela films private limited",
        // OUR PROCESS STEP 4
        opS4Title: "Voiceover",
        opS4Text: "The voiceover is the captivating thread that weaves the story together. It plays a vital role in conveying the information, guiding the audience, and enhancing the overall impact of the explainer video. A well-executed voiceover in a explainer video should be clear, engaging, and aligned with the intended tone and messaging.",
        opS4Img: require("../data/images/process/voiceover.webp"),
        opS4ImgAlt: "animation explainer voiceover novela films private limited",
        // OUR PROCESS STEP 5
        opS5Title: "Storyboarding",
        opS5Text: "A storyboard visually represents the actions and flow of a video, similar to a comic book. The storyboard artist sketches out a series of frames that depict the flow of the video, including the scenes, actions, and characters involved. It serves as a communication tool between creators and clients, facilitating feedback and revisions before production starts.",
        opS5Img: require("../data/images/process/storyboarding.webp"),
        opS5ImgAlt: "animation explainer storyboarding novela films private limited",
        // OUR PROCESS STEP 6
        opS6Title: "Animation",
        opS6Text: "The animation part of an explainer video plays a crucial role in bringing the visual elements to life, enhancing engagement, and effectively communicating the message to the audience. It requires technical skill, creativity, and attention to detail to create animations that are visually appealing, engaging, and forms an emotional connection with the audience.",
        opS6Img: require("../data/images/process/2d-animation.webp"),
        opS6ImgAlt: "animation explainer animation novela films private limited",
        // OUR PROCESS STEP 7
        opS7Title: "Draft Video",
        opS7Text: "Once the production of the explainer video is completed, a draft video will be shared for approval and any suggested changes will be incorporated at this stage.",
        opS7Img: require("../data/images/process/draft-video-1.webp"),
        opS7ImgAlt: "animation explainer draft video novela films private limited",
        // OUR PROCESS STEP 8
        opS8Title: "Iterations",
        opS8Text: "At Novela Films, we include two iterations at each step of the process with a capping of six iterations overall, and we will only proceed once the previous step is approved.",
        opS8Img: require("../data/images/process/iterations.webp"),
        opS8ImgAlt: "animation explainer iterations novela films private limited",
   },
   {
        id: 2,
        key: 2,
        pHeroTitle1: "Our",
        pHeroTitle2: "Process",
        pHeroImg: require("../data/images/process/process-bg.webp"),
        pHeroImgAlt: "video production process novela films private limited",
        pHeroVIDEOURL: "https://www.youtube.com/watch?v=a9pi7X9bHkI",
        pGoogleIMG: require("../data/images/google.webp"),
    }
  ];
  
  export default processData;
  