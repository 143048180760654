import React, { useState } from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function FetchData(){

    const [data, setdata] = useState([])

    const fetchData =() => {
        axios.get("https://novelafilms.com/dbfetch.php")
        .then((response)=>{
            console.log(response);
            setdata(response.data);
        })
    }

    const currentYear = new Date().getFullYear();

  return (
    <>
        <div className='container my-4'>
            <div className='row'>
                <div className='col-4'>
                <h4><img alt="Novela Films" src={require("../data/images/novela-films-logo-sm.webp")} width="30" height="30"
              className="d-inline-block align-top"/> Novela Films</h4></div>
              <div className='col-4'><button onClick={fetchData} className='btn btn-primary'>REFRESH</button></div> 
            </div>   
        </div>

        <div className='container my-4'>
            <div className='row'>
                {
                    data.map((value) => {
                        return (
                            <div className='col-3'>
                            <Card style={{ width: '100%', fontSize:"1.5rem" }}>
                            <Card.Body>
                            <Card.Title>Id: {value.id}</Card.Title>
                            <Card.Title>{value.fullName}</Card.Title>
                            <Card.Text>Email: {value.email}</Card.Text>
                            <Card.Text>MobileNo: {value.phone}</Card.Text>
                            <Card.Text>Position: {value.jobPosition}</Card.Text>
                            <Card.Text>{value.businessType}</Card.Text>
                            <Card.Text>Budget: {value.budget}</Card.Text>
                            <Card.Text>Industry: {value.industry}</Card.Text>
                            <Card.Text>Reference: {value.findAboutUs}</Card.Text>
                            <Card.Text>Message: {value.comments}</Card.Text>
                            <Button variant="primary" disabled>Delete</Button>
                            </Card.Body>
                            </Card>
                            </div>
                        );
                    })
                }
                
            </div>   
        </div>
       
        <p style={{ fontSize:"1.5rem" }}>© 2017-{currentYear} Novela Films Private Limited</p>
       
        
    </>
    
  )
}

export default FetchData