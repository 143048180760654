import React from 'react'
import {RotatingLines} from 'react-loader-spinner';

function Loading() {
  return (
    <div className='preloader'>
    <RotatingLines
  strokeColor="#1D8BCA"
  strokeWidth="5"
  animationDuration="0.75"
  width="96"
  visible={true}
/>
    </div>
  )
}

export default Loading