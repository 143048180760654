import React, { useEffect, useState } from "react";
import Home from "./components/Home";
import Loading from "./components/Loading";

function App() {
  
  const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    })
    return (
      <>
      {isLoading===true?
        <Loading />:
        <>
            <Home />
        </>
        }
        {/* <SmoothScroll /> */}
      </>
    )
}

export default App