import React from "react";
import ParentService from "../../components/OurServices/ParentService";
import LightLayout from "../../Layout/Light";

function ServicesParentPage() {
    return (
        <>
        <LightLayout>
            <ParentService />
        </LightLayout>
        </>
            
         
    )
}

export default ServicesParentPage