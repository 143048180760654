const resources = [
    {
        key: 1,
        id:1,
        blogNAME: "WHY IS THE COST OF VIDEO PRODUCTION SO EXPENSIVE?",
        blogIMG: require("../data/images/blog1.webp"),
        content: "first blog",
        imgDETAIL:"blog on why is the cost of video production so expensive?"
    },
    {
        key: 2,
        id:2,
        blogNAME: "HOW TO IMPROVE YOUTUBE SEARCH RANK?",
        blogIMG: require("../data/images/blog2.webp"),
        content: "second blog",
        imgDETAIL:"blog on how to improve youtube search rank?"
    },
    {
        key: 3,
        id:3,
        blogNAME: "HOW TO SHARE A COMPELLING STORY FOR YOUR NON-PROFIT",
        blogIMG: require("../data/images/blog3.webp"),
        content: "third blog",
        imgDETAIL:"blog on how to share compelling story for your non profit"
    },
    {
        key: 4,
        id:4,
        blogNAME: "how to choose the ideal explainer video company",
        blogIMG: require("../data/images/ebooks/explainer-video-company-ebook.webp"),
        imgDETAIL:"free ebook on how to choose the ideal explainer video company",
        Content1:"This ebook is designed to guide you in choosing the most suitable explainer video company for your business by outlining the important factors to consider. It encompasses :-",       
        Content2:"Budget | Quality & Expertise | Communication | Significance of Client Reviews",
        Content3:"This e-Book specially carters to Small and large businesses looking to create explainer videos.",
        zohoFormsLink:"https://forms.novelafilms.com/nf_forms/form/EBookVideoCompany/formperma/8csXGUPjUoB4zOo1osmlOxUmCw-vem-3prZg8Xi-SDM"
    },
    {
        key: 5,
        id:5,
        blogNAME: "understanding the explainer video process",
        blogIMG: require("../data/images/ebooks/explainer-video-process-ebook.webp"),
        imgDETAIL:"free ebook on understanding the explainer video process",
        Content1:"In this e-Book, we will walk you through our thorough step-by-step approach to the production process of explainer videos. You will gain a clear understanding of what to anticipate at each step.",
        Content2:"Whether you're contemplating a collaboration with us or simply seeking information, this e-Book encompasses all the essential details you need to know.",
        Content3:"This e-Book specially carters to Small and large businesses looking to create explainer videos.",
        zohoFormsLink:"https://forms.novelafilms.com/nf_forms/form/EBookVideoProcess/formperma/HBMT5l8Ygp5hRUu9WLDKsULsVxzQPqb6PXO_dBygvkY"
    },
    {
        key: 6,
        id:6,
        blogNAME: "how to write an explainer video script",
        blogIMG: require("../data/images/ebooks/explainer-video-script-ebook.webp"),
        linkADDR:"/resourses/ebook-download",
        imgDETAIL:"free ebook on how to write a explainer video script",
        Content1:"Master the art of scriptwriting with our comprehensive e-Book. Gain expert insights, step-by-step instructions, and practical tips to create compelling explainer video scripts.",
        Content2:"Discover the classic script structure, learn about optimal video length, and engage your audience with impactful storytelling. Achieve your business goals and elevate your videos to new heights.",
        Content3:"This e-Book specially carters to Small and large businesses looking to create explainer videos.",
        zohoFormsLink:"https://forms.novelafilms.com/nf_forms/form/EBookVideoScript/formperma/WktwOgVxftOhlpNjs1IFXWLdt5RR1w5fQjHtawowZ0E"
    },
    {
        key: 7,
        id:7,
        sliderVL: [101, 102, 103, 1]
    }
    
];

export default resources;