import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import DarkLayout from "../Layout/Dark"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import resourceslist from "../json/resourceslist";
import ErrorPage from "../components/ErrorPage";

function DownloadEbookPage() {
  const { ebookName } = useParams();
  const decodedBookName = decodeURIComponent(ebookName).replace(/-/g, " ");
  const book = resourceslist.find((item) => item.blogNAME === decodedBookName);

  const containerRef = useRef(null);
  const scrollToContainer = () => {
    containerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    
    if (book) {
      toast.info(<h4 className="toastmsg">Kindly Fill The Form Below To Access The E-Book !</h4>);
    }
 };

  if (!book) {
    return (
      <>
        <ErrorPage />
        <h1>Error: Ebook Not Found</h1>
        <h3 className="error-page__message">
          The requested ebook could not be found. Please try again later.
        </h3>
      </>
    );
  }

  return (
    <DarkLayout>
      <Container fluid className="download">
        <Container fluid className="download-section">
          <Row className="justify-content-center">
            <Col lg={6} md={12} sm={12} className="mb-5 mt-5">
              <div>
              <img
              className="ebook-download-img"
                src={book.blogIMG}
                alt={book.imgDETAIL}
              />
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className="ps-3 pe-3 mb-5 mt-5">
              <h2 className="download-title">
                Free E-Book
              </h2>
              <br />
              <h2 className="download-title-n">
                <strong>{book.blogNAME.toUpperCase()}</strong>
              </h2>
              <br />
              <ul className="list-unstyled download-link-text">
                <li>{book.Content1}</li><br/>
                <li>{book.Content2}</li><br/>
                <li>{book.Content3}</li><br/>
              </ul>
              <div>
                <button ref={containerRef} className="color-fill-button" 
                type="submit" id='submit' onClick={scrollToContainer}>
                  <span className="color-fill-button-text">I WANT THIS</span>
                  <span className="color-fill-button-fill"></span>
                </button>
                <ToastContainer position="top-right" autoClose={3000} />
                </div>
            </Col>
          </Row>  
        </Container>
        <hr className="mt-5 mb-5" style={{height:"0.1rem"}} />
        <Container fluid className="download-section">
          <Row className="justify-content-center">
          <h1 className="zoho-form-title">Discover game changing insights - Download our free E-book today!</h1>
           <Col lg={6} md={12} sm={12} className="ps-1 pe-1 mt-5">
              <iframe
                title="ebook download form"
                height="465px"
                width="100%"
                border="none"
                src={book.zohoFormsLink}
              ></iframe>
            </Col>
          </Row>
          </Container>
        </Container>
    </DarkLayout>
  );
}

export default DownloadEbookPage;
