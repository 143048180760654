import React from 'react';
import Blogs from "../components/Blogs";
import DarkLayout from "../Layout/Dark"

const BlogPage = () => {
  return (
    <DarkLayout>
        <Blogs />
    </DarkLayout>
  )
}

export default BlogPage