import React from 'react';
import Video from "react-player";
import { FaTimes } from 'react-icons/fa';
import "./VideoModal.css";

const VideoModal = (props) => {
  return (
    <div className='video-modal'>
            <div className='video-modal-items'>
              <Video
                url={props.videoUrl}
                height="100%"
                width="100%"
                controls={true}
                light={false}
                playing={props.playing}
              />
            </div>
            <div className="video-modal-close" onClick={props.closeButton}>
              <FaTimes className="video-modal-close-icon" />
            </div>
    </div>
  )
}

export default VideoModal