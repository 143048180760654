import React, {useState} from 'react';
import "./HeroVideo.css";
import { Col, Container, Row } from 'react-bootstrap';
import posterbg from "../../data/images/showreel-bg-default.webp";
import { FaStar, FaPlay} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import VideoModal from '../Modal/VideoModal';

function VideoBg () {

  const [isOpen, setIsOpen] = useState(false);

  const openWindow = () => {
    setIsOpen(true);
  };

  const closeWindow = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="video-bg">
      <picture>
        <source srcSet={require("../../data/images/novela-films-logo-sm.webp")} 
        media="(max-width: 320px)" />
        <img src={posterbg} alt="novela-films-showreel" className='video-bg-img' />
      </picture>
      <div className="overlay"></div>
      <Container fluid className='hero-container'>
            <Row>
              <Col md={6} xs={12} className='p-0'>
              {/* Hero Title */}
              <div className='hero-title-container'>
                <h2 className='hero-title'>Let's</h2><br/>
                <h2 className='hero-title'>Make</h2>
                <h2 className='hero-title'>a</h2><br/>
                <h2 className='hero-title'>Video</h2>
              </div> 
                {/* Hero Quote Button */}
              <div className='hero-quote'>
              <Link to="/contact/" className="hero_quote_button">
              <span className="hero_quote_button_text">GET QUOTE</span>
              <span className="hero_quote_button_fill"></span>
              </Link>
              </div>              
              {/* Hero Rating */}
              <div className='hero-rating'>
              <a href="https://www.google.com/search?q=novela+films" 
              target="_blank" rel="noopener noreferrer" className="hero-rating-button"> 
              <img src={require("../../data/images/google.webp")} alt='google-logo'
              className='hero-rating-logo' />
              {Array(5).fill().map((_, index) => (<FaStar key={index} className='hero-rating-star' />))}
              <p>Rating 4.7</p></a>
              <a href="https://clutch.co/profile/novela-films#highlights" 
              target="_blank" rel="noopener noreferrer" className="services-hero-rating-button"> 
              <img style={{height:"100%", width:"100%"}}
               src={require("../../data/images/clutch.webp")} 
               alt='google-logo' className='services-hero-rating-logo' />
              {Array(5).fill().map((_, index) => (<FaStar key={index} className='services-hero-rating-star' />))}
              <p>Rating 5.0</p></a>
              </div>
              </Col>

              <Col md={6} xs={12} className='hero-play p-0'>
              {/* Hero Play Button */}
              <button id='hpb' title='Play' className="hero-play-button" onClick={openWindow}>
              <span className="hero-play-button_text">
              <FaPlay className=''/></span>
              <span className="hero-play-button_fill"></span>
              </button>
              <p>SHOWREEL</p>
              </Col>
            </Row>

            {/* FOR MOBILE ONLY */}
            {/* <div className='mobile-items'> */}
                 {/* Hero Quote Button */}
            {/* <div>
              <Link to="/contact/" className="hero_quote_button">
              <span className="hero_quote_button_text">GET QUOTE</span>
              <span className="hero_quote_button_fill"></span>
              </Link>
              </div>               */}
              {/* Hero Rating */}
              {/* <div className='hero-rating'>
              <a href="https://www.google.com/search?q=novela+films" 
              target="_blank" rel="noopener noreferrer" className="hero-rating-button"> 
              <img src={require("../../data/images/google.png")} alt='google-logo'
              className='hero-rating-logo' />
              {Array(5).fill().map((_, index) => (<FaStar key={index} className='hero-rating-star' />))}
              <p>Rating 4.7</p></a>
              </div>
            </div> */}
             
    </Container>

            {isOpen && ( <VideoModal 
            videoUrl= "https://www.youtube.com/embed/q-4U5lC4tTU"
             playing={true}
             closeButton={closeWindow} />
            )}
      </div>
    </>
  )
}

export default VideoBg