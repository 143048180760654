import React from 'react';

function WhatsAppbtn () {
  return (
    <>
    <div className=" whatsapp_float ">
      
      <a href="https://wa.me/918847516961?text=Hi" target="_blank" rel="noopener noreferrer">
      <img src={require("../data/images/whatsapp-logo.webp")} 
      alt="walogo" className="whatsapp_float_btn"/></a> 
     
        <div className="waoverlay">
          <div>
          <a href="https://wa.me/918847516961?text=Hi" target="_blank" rel="noopener noreferrer">
          <p className='text'>CLICK TO CHAT</p></a>
          </div>
        </div>
      </div>
    </>    
  )
}

export default WhatsAppbtn