import React, {useEffect, useState } from "react";
import DarkNavigationBar from "../components/DarkNavigation/DarkNavigationBar"
import Action from '../components/Action';
import Footer from '../components/Footer';
import GoToTop from '../components/GoToTop';
import Loading from "../components/Loading";
const LazyPartnerLogo = React.lazy(() => import("../components/PartnerLogo"));
const LazyContactForm = React.lazy(() => import("../components/Contact"));


function DarkLayout({children}) {

  const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })

  return (
    <>
        {isLoading===true?
            <Loading />:
            <>
            <DarkNavigationBar />
        {/* Any Page Content */}
        {children}
        <LazyPartnerLogo />
        <LazyContactForm />
        <Action />
        <Footer />
        {/* <WhatsAppWidget phoneNumber="8847516961" /> */}
        <GoToTop />
        </>
        } 
        </> 
  )
}

export default DarkLayout;