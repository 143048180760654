import React, { useState } from "react";
import DarkLayout from "../Layout/Dark"
import ImageModal from "../components/Modal/ImageModal";
import data from "../json/gallery";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

function Gallerypage() {

    const [isOpen, setIsOpen] = useState(false);
    const [tempimgSrc, setTempimgSrc] = useState("");

  const openWindow = (imgSrc) => {
    setTempimgSrc(imgSrc);
    setIsOpen(true);
  };

  const closeWindow = () => {
    setIsOpen(false);
  };
  
    return (
      <>
        <Helmet>
        <title>Our Gallery - Novela Films</title>
        <link rel="canonical" href="https://novelafilms.com/gallery" />
        {/* Meta tags */}
        <meta name="description" content="A picture is worth a thousand words, but a memory is priceless" />
        <meta name="keywords" content="Our Gallery, Novela Films" />
        <meta property="og:title" content="Our Gallery - Novela Films" />
        <meta property="og:description" content="A picture is worth a thousand words, but a memory is priceless" />
        <meta property="og:image" content="https://novelafilms.com/novela-films.png" />
        </Helmet>

    <DarkLayout>
      <Container fluid className="gallery">
      <h4 style={{textAlign:"left"}}><Link to="/">HOME </Link>/ GALLERY</h4>
      <h1 className="quotes">“A picture is worth a thousand words,<br/> but a memory is priceless”</h1>
            
    <div className="gallery-column">
      {data.filter(item => item.key >= 0 && item.key <= 24).map((item, index)=>{
      return(
            <div className="pics" key={index} 
          onClick={() => openWindow(item.galImgSrc)}>
          <picture>
          <source srcSet={item.galImg_SM} media="(max-width: 576px)" />
          <source srcSet={item.galImg_MD} media="(max-width: 768px)" />
          <source srcSet={item.galImg_LG} media="(max-width: 992px)" />
          <img style={{height:"100%", width:"100%"}} src={item.galImgSrc} 
            alt={item.galImgAlt} />
          </picture>
          </div>
        )
    })}
            
    </div>
    </Container>
    {isOpen && ( <ImageModal imageSrc={tempimgSrc} closeButton={closeWindow} />)}
  </DarkLayout>
  </>
            
    )
}

export default Gallerypage;