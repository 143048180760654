import React, {useState } from "react";
import { Helmet } from "react-helmet-async";
import videos from "../json/videos";
import VideoModal from "../components/Modal/VideoModal";
import Portfoliocard from "../components/Portfoliocard";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import DarkLayout from "../Layout/Dark"

function Portfoliopage() {

    const [isOpen, setIsOpen] = useState(false);
    const [tempvideoSrc, setTempvideoSrc] = useState("");

  const openWindow = (videoSrc) => {
    setTempvideoSrc(videoSrc);
    setIsOpen(true);
  };

  const closeWindow = () => {
    setIsOpen(false);
  };

    return (
      <>
        <Helmet>
        <title>Our Portfolio - Novela Films</title>
        <link rel="canonical" href="https://novelafilms.com/portfolio/" />
        {/* Meta tags */}
        <meta name="description" content="Take a tour of our portfolio and you’ll see a perfect blend of creativity and innovation." />
        <meta name="keywords" content="Our Portfolio, Novela Films" />
        <meta property="og:title" content="Our Portfolio - Novela Films" />
        <meta property="og:description" content="Take a tour of our portfolio and you’ll see a perfect blend of creativity and innovation." />
        <meta property="og:image" content="https://novelafilms.com/novela-films.png" />
        </Helmet>

        <DarkLayout>
          <Container fluid className="portfolio">
        <h4 style={{textAlign:"left"}}><a href="/">HOME </a>/ PORTFOLIO</h4>
        <h1 className="quotes">“Let our work do the talking.”</h1>
        </Container>

        <Container fluid style={{margin:"1% auto 5%", padding:" 0 0.5%"}}>
            <Row className="portfoliogallery">
                {videos.filter(item => item.key <= 67).map((item, index)=>{
                    return(
                        <Col lg={4} md={4} sm={12} xs={12}
                        className="p-0" key={index} onClick={() => openWindow(item.videoURL)}>
                                <Portfoliocard 
                                imgSrc={item.imgSrc}
                                imgAlt={item.name}
                                name={item.name}
                                duration={item.duration}
                                industry={item.industry}
                                
                            />
                            </Col>
                            )
                            })}
            </Row>
            {isOpen && (
                <VideoModal videoUrl={tempvideoSrc} closeButton={closeWindow} />
            )}
             
        </Container>
        </DarkLayout>
      </>
    )
}

export default Portfoliopage