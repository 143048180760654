const blogs = [
    {
        key: 1,
        id: 1,
        blogName: "blog one",
        blogImg: require("../data/images/blog1.webp"),
        blogText: "blog 1 text"

    },
    {
        key: 2,
        id: 2,
        blogName: "blog two",
        blogImg: require("../data/images/blog2.webp"),
        blogText: "blog 2 text"

    },
    {
        key: 3,
        id: 3,
        blogName: "blog three",
        blogImg: require("../data/images/blog3.webp"),
        blogText: "blog 3 text"

    }    
];

export default blogs;