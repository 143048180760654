import React from 'react'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import data from "../../json/gallery";
import "./PartnerLogos.css";

function PartnerLogo() {
  return (
    <>
      <div><p className='partner-logo-text'>Admired by hundreds of brands</p></div>
    <Container fluid className='partners'>
      <Row className="">
                {data.filter(item => item.key <= 36).map((item, index)=>{
                    return(
                        <Col lg={2} md={3} sm={4} xs={4} 
                        className='partner-logo' key={index}>
                        <img src={item.partnerImgSrc} 
                        style={{width: "90%", height:"100%"}} 
                        alt={item.partnerImgAlt} />
                        </Col>
                        )
                        })}
                           
            </Row>  
        </Container>
    </>
    
    
  )
}

export default PartnerLogo