import React from 'react';
import resourceslist from "../json/resourceslist";
import Resourcescard from "../components/Resources/Resourcescard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";

const EbookComponent = () => {
  const navigate = useNavigate();

  const handleBookSelect = (book) => {
    navigate('/resourses/ebook-download', { state: {book} });
    
  };

      return (
        <>
          <Container fluid className='p-0 ps-2 pe-2 mt-5 mb-5'>
          <h2 className="section-heading mb-5">E-Books</h2>
          <Container fluid className="mt-3">
          <Row className="mb-5">
          {resourceslist.filter(ebook => ebook.key >= 4 && ebook.key <=6 ).map((ebook) => (
                          <Col lg={4} md={4} sm={12} xs={12} key={ebook.id}
                          onClick={() => handleBookSelect(ebook)} >
                                  <Resourcescard 
                                  key={ebook.key}
                                  blogNAME={ebook.blogNAME}
                                  blogIMG={ebook.blogIMG}
                                  // linkADDR={ebook.linkADDR}
                                  imgDETAIL={ebook.imgDETAIL}
                              />
                              {/* <button onClick={() => handleBookSelect(ebook)}>Select</button> */}
                              </Col>
                             ))}
            </Row>
            </Container>
          </Container>
        </>

        );
      }

export default EbookComponent;
