import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/esm/Row';

import { Col, Container } from 'react-bootstrap';


function ClientPageCardS(props) {
    return (
        <Container fluid className='p-0'>
        <Row >
        <Col lg={4} md={4} sm={12} xs={12} className='p-0'>
        <Card style={{height: "100%"}}>
        <Card.Img style={{height: "100%", width:"100%"}} src={props.clientIMG} alt={props.clientNAME} />
        </Card>
        </Col>

        <Col lg={8} md={8} sm={12} xs={12} className='p-0'>
           
      {/* <div className="linkedin">
      <a href={props.clientLINK} target="_blank" rel="noopener noreferrer">
      <img
          className="highlight" style={{width:"3rem", height:"3rem"}}
          src={require("../data/images/testimonials/linkedin.webp")} 
          alt="linkedin"
        /></a> 
      </div> */}
      <Card style={{height: "100%"}}>

      <Card.Header className="client-brands">
      <Card.Img className='client-brands-img'
      style={{height: "100%", width:"100%"}}  
      src={props.clientBRANDIMG} 
      alt={props.clientNAME} />
      </Card.Header>
    
      <Card.Body >
      <Card.Text className="client-feedtext p-0">{props.clientFEEDBACK}</Card.Text>
      </Card.Body>
      <Card.Footer className="bottomc">
      <Card.Title className='client-title'><strong>{props.clientNAME}</strong>&nbsp;
      <a href={props.clientLINK} target="_blank" rel="noopener noreferrer">
      <img className='linkedinClients' style={{width:"2.5rem", height:"2.5rem"}}
          src={require("../data/images/testimonials/linkedinc.webp")} 
          alt="linkedin"
        /></a>
      </Card.Title>
      <Card.Title className='client-title'><i>{props.clientPOSITION}</i></Card.Title>
      </Card.Footer>
      
      </Card>      
      
    </Col>
    </Row>
    
    </Container>
        
      )
  }

export default ClientPageCardS