import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {FaFacebookF, FaInstagram, FaLinkedin, FaYoutube} from "react-icons/fa";
import "./Action.css";

function Action() {
    return (
    <>
      <Container fluid className="action">
        <Row>
        <Col lg={6} md={6} sm={12} >
        <picture>
          <source srcSet={require("../../data/images/novela-films-white-logo-sm.webp")} 
          media="(max-width: 320px)" />
          <img style={{height:"100%", width:"100%"}} className="action-logo" 
          alt="Novela Films White Logo" 
        src={require("../../data/images/novela-films-white-logo-default.webp")}
        />
        </picture>
        
        </Col>
            
      <Col lg={6} md={6} sm={12} className="action-items">

      <ul className="list-unstyled" >
        <li className="action-text">NOVELA FILMS PRIVATE LIMITED</li>
        <li className="action-text">OFFICES: MUMBAI & ALL OVER THE WORLD</li>
        <li className="action-text">EMAIL:
        <a href="mailto:video@novelafilms.com"> VIDEO@NOVELAFILMS.COM</a></li>
        <li className="action-text">JOBS AT:
        <a href="https://www.novelafilms.com"> VIEW OPEN POSITIONS</a></li>
        <li className="action-text">CALL US : +91 9960358744</li>
      </ul>
      
      <div className='action-social-items'>
        <span className='action-social-item linkedin'>
        <a href="https://www.linkedin.com/company/novela-films/mycompany/" 
        target="_blank" rel="noopener noreferrer" title="linkedin">
        <FaLinkedin className="action-social-logo" /></a></span>
        <span className='action-social-item youtube'>
        <a href="https://www.youtube.com/channel/UCU678vumQHBerlq9E7Ov2Dg" 
        target="_blank" rel="noopener noreferrer" title="youtube">
        <FaYoutube className="action-social-logo" /></a></span>
        <span className='action-social-item instagram'>
        <a href="https://www.instagram.com/novelafilmsofficial/" 
        target="_blank" rel="noopener noreferrer" title="instagram">
        <FaInstagram className="action-social-logo" /></a></span>
        <span className='action-social-item facebook'>
        <a href="https://www.facebook.com/NovelaFilmsMumbai" 
        target="_blank" rel="noopener noreferrer" title="facebook">
        <FaFacebookF className="action-social-logo" /></a></span>
      </div>

      </Col>
      </Row>
      </Container>
    </>
    )
}

export default Action