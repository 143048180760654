import React from 'react';
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function Top() {
  return (
    <div>
      <ScrollToTop smooth 
      color='#1D8BCA'
      component={<p>SCROLL TOP &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowUp}/></p>} 
      className="scrolltotop"
       />
      
    </div>
  )
}

export default Top