const data  = [
    {
        key: 1,
        galImgSrc: require("../data/images/gallery/beds1-default.webp"),
        galImg_SM: require("../data/images/gallery/beds1-sm.webp"),
        galImg_MD: require("../data/images/gallery/beds1-md.webp"),
        galImg_LG: require("../data/images/gallery/beds1-lg.webp"),
        galImgAlt: "ankit baradia ceo photoshoot",
        partnerImgSrc: require("../data/images/companylogos/new-india-pl1.webp"),
        partnerImgAlt: "new india cooperative bank limited"        
    },
    {
        key: 2,
        galImgSrc: require("../data/images/gallery/beds2-default.webp"),
        galImg_SM: require("../data/images/gallery/beds2-md.webp"),
        galImg_MD: require("../data/images/gallery/beds2-md.webp"),
        galImg_LG: require("../data/images/gallery/beds2-lg.webp"),
        galImgAlt: "ankit baradia ceo photoshoot",
        partnerImgSrc: require("../data/images/companylogos/wonder-cement-pl2.webp"),
        partnerImgAlt: "wonder cement"
    },
    {
        key: 3,
        galImgSrc: require("../data/images/gallery/beds3-default.webp"),
        galImg_SM: require("../data/images/gallery/beds3-sm.webp"),
        galImg_MD: require("../data/images/gallery/beds3-md.webp"),
        galImg_LG: require("../data/images/gallery/beds3-lg.webp"),
        galImgAlt:"ankit baradia ceo photoshoot",
        partnerImgSrc: require("../data/images/companylogos/aditya-birla-pl3.webp"),
        partnerImgAlt: "aditya birla group"
    },
    {
        key: 4,
        galImgSrc: require("../data/images/gallery/beds4-default.webp"),
        galImg_SM: require("../data/images/gallery/beds4-sm.webp"),
        galImg_MD: require("../data/images/gallery/beds4-md.webp"),
        galImg_LG: require("../data/images/gallery/beds4-lg.webp"),
        galImgAlt:"ankit baradia ceo photoshoot",
        partnerImgSrc: require("../data/images/companylogos/quantiphi-pl4.webp"),
        partnerImgAlt: "quantiphi"
    },
    {
        key: 5,
        galImgSrc: require("../data/images/gallery/beds5-default.webp"),
        galImg_SM: require("../data/images/gallery/beds5-sm.webp"),
        galImg_MD: require("../data/images/gallery/beds5-md.webp"),
        galImg_LG: require("../data/images/gallery/beds5-lg.webp"),
        galImgAlt:"ankit baradia ceo photoshoot",
        partnerImgSrc: require("../data/images/companylogos/pc-chandra-pl5.webp"),
        partnerImgAlt: "pc chandra jwellers"
    },
    {
        key: 6,
        galImgSrc: require("../data/images/gallery/beds6-default.webp"),
        galImg_SM: require("../data/images/gallery/beds6-sm.webp"),
        galImg_MD: require("../data/images/gallery/beds6-md.webp"),
        galImg_LG: require("../data/images/gallery/beds6-lg.webp"),
        galImgAlt:"ankit baradia ceo photoshoot",
        partnerImgSrc: require("../data/images/companylogos/betterplace-pl6.webp"),
        partnerImgAlt: "betterplace"
    },
    {
        key: 7,
        galImgSrc: require("../data/images/gallery/beds7-default.webp"),
        galImg_SM: require("../data/images/gallery/beds7-sm.webp"),
        galImg_MD: require("../data/images/gallery/beds7-md.webp"),
        galImg_LG: require("../data/images/gallery/beds7-lg.webp"),
        galImgAlt:"ankit baradia ceo photoshoot",
        partnerImgSrc: require("../data/images/companylogos/yaarii-pl7.webp"),
        partnerImgAlt: "yaarii"
    },
    {
        key: 8,
        galImgSrc: require("../data/images/gallery/beds8-default.webp"),
        galImg_SM: require("../data/images/gallery/beds8-sm.webp"),
        galImg_MD: require("../data/images/gallery/beds8-md.webp"),
        galImg_LG: require("../data/images/gallery/beds8-lg.webp"),
        galImgAlt:"ankit baradia ceo photoshoot",
        partnerImgSrc: require("../data/images/companylogos/yes-bank-pl8.webp"),
        partnerImgAlt: "yes bank"
    },
    {
        key: 9,
        galImgSrc: require("../data/images/gallery/purplle1-default.webp"),
        galImg_SM: require("../data/images/gallery/purplle1-sm.webp"),
        galImg_MD: require("../data/images/gallery/purplle1-md.webp"),
        galImg_LG: require("../data/images/gallery/purplle1-lg.webp"),
        galImgAlt:"purplle cosmetics company",
        partnerImgSrc: require("../data/images/companylogos/wrm-pl9.webp"),
        partnerImgAlt: "wrm"
    },
    {
        key: 10,
        galImgSrc: require("../data/images/gallery/purplle2-default.webp"),
        galImg_SM: require("../data/images/gallery/purplle2-sm.webp"),
        galImg_MD: require("../data/images/gallery/purplle2-md.webp"),
        galImg_LG: require("../data/images/gallery/purplle2-lg.webp"),
        galImgAlt:"purplle cosmetics company",
        partnerImgSrc: require("../data/images/companylogos/sud-life-pl10.webp"),
        partnerImgAlt: "sud life"
    },
    {
        key: 11,
        galImgSrc: require("../data/images/gallery/purplle3-default.webp"),
        galImg_SM: require("../data/images/gallery/purplle3-sm.webp"),
        galImg_MD: require("../data/images/gallery/purplle3-md.webp"),
        galImg_LG: require("../data/images/gallery/purplle3-lg.webp"),
        galImgAlt:"purplle cosmetics company",
        partnerImgSrc: require("../data/images/companylogos/winbee-pl11.webp"),
        partnerImgAlt: "winbee"
    },
    {
        key: 12,
        galImgSrc: require("../data/images/gallery/purplle4-default.webp"),
        galImg_SM: require("../data/images/gallery/purplle4-sm.webp"),
        galImg_MD: require("../data/images/gallery/purplle4-md.webp"),
        galImg_LG: require("../data/images/gallery/purplle4-lg.webp"),
        galImgAlt:"purplle cosmetics company",
        partnerImgSrc: require("../data/images/companylogos/lead-school-pl12.webp"),
        partnerImgAlt: "lead school"
    },
    {
        key: 13,
        galImgSrc: require("../data/images/gallery/purplle5-default.webp"),
        galImg_SM: require("../data/images/gallery/purplle5-sm.webp"),
        galImg_MD: require("../data/images/gallery/purplle5-md.webp"),
        galImg_LG: require("../data/images/gallery/purplle5-lg.webp"),
        galImgAlt:"purplle cosmetics company",
        partnerImgSrc: require("../data/images/companylogos/uniapply-pl13.webp"),
        partnerImgAlt: "uniapply"
    },
    {
        key: 14,
        galImgSrc: require("../data/images/gallery/novela-films-office-default.webp"),
        galImg_SM: require("../data/images/gallery/novela-films-office-sm.webp"),
        galImg_MD: require("../data/images/gallery/novela-films-office-md.webp"),
        galImg_LG: require("../data/images/gallery/novela-films-office-lg.webp"),
        galImgAlt:"novela films private limited office",
        partnerImgSrc: require("../data/images/companylogos/teamlease-pl14.webp"),
        partnerImgAlt: "teamlease"
    },
    {
        key: 15,
        galImgSrc: require("../data/images/gallery/short1.webp"),
        galImgAlt:"the broken gift short movie by novela films",
        partnerImgSrc: require("../data/images/companylogos/stockholding-pl15.webp"),
        partnerImgAlt: "stockholding"
    },
    {
        key: 16,
        galImgSrc: require("../data/images/gallery/short2.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/bt-group-pl16.webp"),
        partnerImgAlt: "bt group"
    },
    {
        key: 17,
        galImgSrc: require("../data/images/gallery/short3.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/sbi-life-pl17.webp"),
        partnerImgAlt: "sbi life"
    },
    {
        key: 18,
        galImgSrc: require("../data/images/gallery/short4.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/purplle-pl18.webp"),
        partnerImgAlt: "purplle"
    },
    {
        key: 19,
        galImgSrc: require("../data/images/gallery/short5.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/muskurahat-pl19.webp"),
        partnerImgAlt: "muskurahat"
    },
    {
        key: 20,
        galImgSrc: require("../data/images/gallery/short6.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/godrej-pl20.webp"),
        partnerImgAlt: "godrej"
    },
    {
        key: 21,
        galImgSrc: require("../data/images/gallery/short7.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/dailyhunt-pl21.webp"),
        partnerImgAlt: "dailyhunt"
    },
    {
        key: 22,
        galImgSrc: require("../data/images/gallery/short8.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/koncept-pl22.webp"),
        partnerImgAlt: "koncept"
    },
    {
        key: 23,
        galImgSrc: require("../data/images/gallery/short9.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/indiabulls-pl23.webp"),
        partnerImgAlt: "indiabulls"
    },
    {
        key: 24,
        galImgSrc: require("../data/images/gallery/short10.webp"),
        galImgAlt:"the broken gift short movie behind the scenes",
        partnerImgSrc: require("../data/images/companylogos/iffco-tokio-pl24.webp"),
        partnerImgAlt: "iffco tokio"
    },
    {
        key: 25,
        partnerImgSrc: require("../data/images/companylogos/hexaware-pl25.webp"),
        partnerImgAlt: "hexaware"
    },
    {
        key: 26,
        partnerImgSrc: require("../data/images/companylogos/hdfc-bank-pl26.webp"),
        partnerImgAlt: "hdfc bank"
    },
    {
        key: 27,
        partnerImgSrc: require("../data/images/companylogos/prudent-pl27.webp"),
        partnerImgAlt: "prudent"
    },
    {
        key: 28,
        partnerImgSrc: require("../data/images/companylogos/ey-pl28.webp"),
        partnerImgAlt: "ey"
    },
    {
        key: 29,
        partnerImgSrc: require("../data/images/companylogos/eatigo-pl29.webp"),
        partnerImgAlt: "eatigo"
    },
    {
        key: 30,
        partnerImgSrc: require("../data/images/companylogos/bajaj-finserv-pl30.webp"),
        partnerImgAlt: "bajaj finserv"
    },
    {
        key: 31,
        partnerImgSrc: require("../data/images/companylogos/reliance-nippon-pl31.webp"),
        partnerImgAlt: "reliance nippon"
    },
    {
        key: 32,
        partnerImgSrc: require("../data/images/companylogos/betterplace-pl32.webp"),
        partnerImgAlt: "betterplace"
    },
    {
        key: 33,
        partnerImgSrc: require("../data/images/companylogos/neogrowth-pl33.webp"),
        partnerImgAlt: "neogrowth"
    },
    {
        key: 34,
        partnerImgSrc: require("../data/images/companylogos/muthoot-pl36.webp"),
        partnerImgAlt: "muthoot"
    },
    {
        key: 35,
        partnerImgSrc: require("../data/images/companylogos/engagely-pl35.webp"),
        partnerImgAlt: "engagely"
    },
    {
        key: 36,
        partnerImgSrc: require("../data/images/companylogos/avanse-pl34.webp"),
        partnerImgAlt: "avanse"        
    }
];

export default data;