import React from "react";
import Card from "react-bootstrap/Card";

function Portfoliocard(props) {
    return (  
        <Card className="card-pc" >
        <Card.Img className="imgpc" src={props.imgSrc} 
        style={{height:"100%", width:"100%"}} alt={props.imgAlt} />
        <div className="middle">
        <Card.Body className="text">
        <h2><span className="borbtm">{props.name}</span></h2><br/>
        <h3>Duration: {props.duration}</h3>
        <h3>Industry: {props.industry}</h3>
        </Card.Body>
        </div>
        <Card.Img className="video-button" 
        src={require("../data/images/youtube-button.webp")} 
        alt="youtube play button" />
        </Card>
          
    )
}

export default Portfoliocard;