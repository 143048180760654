
const videos = [
    {
        key:1,
        id: 1,
        name:"HDFC Life",
        duration:"1:42 Minutes",
        industry:"Life Insurance Solutions Provider",
        imgSrc: require("../data/images/portfolio/hdfc-life-pv1.webp"),
        imgAlt:"HDFC Life",
        videoURL:"https://www.youtube.com/embed/SzvFZ4L9XxI"
    },
    {
        key:2,
        id: 2,
        name:"Hexaware",
        duration:"2:43 Minutes",
        industry:"IT, BPO, and Consulting Services",    
        imgSrc: require("../data/images/portfolio/hexaware-pv2.webp"),
        imgAlt:"Hexaware",
        videoURL:"https://www.youtube.com/embed/-fwiaCq7rpk"
    },
    {
        key:3,
        id: 3,
        name:"Yaari",
        duration:"2:02 Minutes",
        industry:"Financial Services",
        imgSrc: require("../data/images/portfolio/yaari-pv3.webp"),
        imgAlt:"Yaari",
        videoURL:"https://www.youtube.com/embed/t8QTH9q_nlw"
    },
    {
        key:4,
        id: 4,
        name:"Flipkart Fashion",
        duration:"44 Seconds",
        industry:"E-commerce Company",
        imgSrc: require("../data/images/portfolio/flipkart-fashion-pv4.webp"),
        imgAlt:"Flipkart Fashion",
        videoURL:"https://www.youtube.com/embed/_qspBFKVHhY"
    },
    {
        key:5,
        id: 5,
        name:"UpGrad",
        duration:"2:53 Minutes",
        industry:"Online Higher Education",   
        imgSrc: require("../data/images/portfolio/upgrad-pv5.webp"),
        imgAlt:"UpGrad",
        videoURL:"https://www.youtube.com/embed/H2XlsaibeVw"
    },
    {
        key:6, 
        id: 6,
        name:"PlayerzPot",
        duration:"10 Seconds",
        industry:"Online Educational Services",
        imgSrc: require("../data/images/portfolio/playerzpot-pv6.webp"),
        imgAlt:"PlayerzPot",
        videoURL:"https://www.youtube.com/embed/N0RntDGOEdM"
    },
    {
        key:7,
        id: 7,
        name:"P C Chandra Jewellers",
        duration:"1:05 Minutes",
        industry:"Jewellers",
        imgSrc: require("../data/images/portfolio/pc-chandra-pv7.webp"),
        imgAlt:"P C Chandra Jewellers",
        videoURL:"https://www.youtube.com/embed/xNzmtBd6B2w"
    },
    {
        key:8,
        id: 8, 
        name:"EY",
        duration:"50  Seconds",
        industry:"Health Insurance",
        imgSrc: require("../data/images/portfolio/ey-pv8.webp"),
        imgAlt:"EY",
        videoURL:"https://www.youtube.com/embed/eyoJBgqD7sM"
    },
    {
        key:9,
        id: 9,
        name:"Mahindra & Mahindra",
        duration:"45 Seconds",
        industry:"Automotive and Farm Equipment",
        imgSrc: require("../data/images/portfolio/mahindra-pv9.webp"),
        imgAlt:"Mahindra & Mahindra",
        videoURL:"https://www.youtube.com/embed/R7g85wuLJXw"
    },
    {
        key:10,
        id: 10,
        name:"HP4L",
        duration:"1:18 Minutes",
        industry:"Insuarance Broker",
        imgSrc: require("../data/images/portfolio/hp4l-pv10.webp"),
        imgAlt:"HP4L",
        videoURL:"https://www.youtube.com/embed/9c5Akl2-W3A"
    },
    {
        key:11,
        id: 11,
        name:"SUD life",
        duration:"3:06 Minutes",
        industry:"Life Insurance",
        imgSrc: require("../data/images/portfolio/sud-life-pv11.webp"),
        imgAlt:"SUD life",
        videoURL:"https://www.youtube.com/embed/h4R9M_VT5mM"
    },
    {
        key:12,
        id: 12,
        name:"StockHolding(SHCIL)",
        duration:"2:09 Minutes",
        industry:"Stock Market & Financial Services",
        imgSrc: require("../data/images/portfolio/shcil-pv12.webp"),
        imgAlt:"StockHolding(SHCIL)",
        videoURL:"https://www.youtube.com/embed/0sbjI9osuok"
    },
    {
        key:13,
        id: 13,
        name:"MSSI",
        duration:"4:16 Minutes",
        industry:"NGO",
        imgSrc: require("../data/images/portfolio/mssi-pv13.webp"),
        imgAlt:"MSSI",
        videoURL:"https://www.youtube.com/embed/oC-mG-6ct60"
    },
    {
        key:14,
        id: 14,
        name:"Mahindra & Mahindra",
        duration:"5:18 Minutes",
        industry:"Automotive",
        imgSrc: require("../data/images/portfolio/mahindra-pv14.webp"),
        imgAlt:"Mahindra & Mahindra",
        videoURL:"https://www.youtube.com/embed/KA55qDR5lWo"
    },
    {
        key:15,
        id: 15, 
        name:"Yes bank",
        duration:"56 Seconds",
        industry:"Full Service Commercial Bank",
        imgSrc: require("../data/images/portfolio/yes-bank-pv15.webp"),
        imgAlt:"Yes bank",
        videoURL:"https://www.youtube.com/embed/Q6smqat7JJk"
    },
    {
        key:16,
        id: 16, 
        name:"MSSI",
        duration:"16:35 Minutes",
        industry:"NGO",
        imgSrc: require("../data/images/portfolio/mssi-pv16.webp"),
        imgAlt:"MSSI",
        videoURL:"https://www.youtube.com/embed/OmzE8lWZT7w"
    },
    {
        key:17,
        id: 17, 
        name:"New India Co-operative Bank",
        duration:"1:28 Minutes",
        industry:"Banking, Financials Services",
        imgSrc: require("../data/images/portfolio/new-india-bank-pv17.webp"),
        imgAlt:"New India Co-operative Bank",
        videoURL:"https://www.youtube.com/embed/N09zYLrZTnQ"
    },
    {
        key:18,
        id: 18, 
        name:"HDFC Life",
        duration:"5:52 Minutes",
        industry:"Life Insurance Solutions Provider",
        imgSrc: require("../data/images/portfolio/hdfc-life-pv18.webp"),
        imgAlt:"HDFC Life",
        videoURL:"https://www.youtube.com/embed/v55UFvGlZhI"
    },
    {
        key:19,
        id: 19, 
        name:"Millionworks",
        duration:"3:55 Minutes",
        industry:"Music Video Production", 
        imgSrc: require("../data/images/portfolio/millionworks-pv19.webp"),
        imgAlt:"Millionworks",
        videoURL:"https://www.youtube.com/embed/frM-siI3bKA"
    },
    {
        key:20,
        id: 20, 
        name:"Novela films",
        duration:"19:12 Minutes",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/novela-films-pv20.webp"),
        imgAlt:"Novela films",
        videoURL:"https://www.youtube.com/embed/DVto7SumZ9I"
    },
    {
        key:21,
        id: 21, 
        name:"One Degree",
        duration:"1:15 Minutes",
        industry:"Apperal", 
        imgSrc: require("../data/images/portfolio/one-degree-pv21.webp"),
        imgAlt:"One Degree",
        videoURL:"https://www.youtube.com/embed/c76w9jxikzk"
    },
    {
        key:22,
        id: 22, 
        name:"Prudent Broker", 
        duration:"1:49 Minutes",
        industry:"Insuarance Broker",
        imgSrc: require("../data/images/portfolio/prudent-broker-pv22.webp"),
        imgAlt:"Prudent Broker",
        videoURL:"https://www.youtube.com/embed/KLxOGW9eQnA"
    },
    {
        key:23,
        id: 23, 
        name:"Purplle",
        duration:"0:57 Minutes",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv23.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/vpjFyGEOTBA"
    },
    {
        key:24,
        id: 24, 
        name:"Apple music",
        duration:"17:35 Minutes",
        industry:"Technology company",
        imgSrc: require("../data/images/portfolio/apple-music-pv24.webp"),
        imgAlt:"Apple music",
        videoURL:"https://www.youtube.com/embed/vILI7W5TdJ0"
    },
    {
        key:25,
        id: 25, 
        name:"BETTER EARTH",
        duration:"4:18 Minutes",
        industry:"Diamond industory",
        imgSrc: require("../data/images/portfolio/better-earth-pv25.webp"),
        imgAlt:"BETTER EARTH",
        videoURL:"https://www.youtube.com/embed/kL26_DlrqvQ"
    },
    {
        key:26,
        id: 26, 
        name:"Winbee",
        duration:" 31 Seconds",
        industry:"Retail Luxury Goods and Jewellery",
        imgSrc: require("../data/images/portfolio/winbee-pv26.webp"),
        imgAlt:"Winbee",
        videoURL:"https://www.youtube.com/embed/oFWfaajWPfg"
    },
    {
        key:27,
        id: 27, 
        name:"Lead school",
        duration:"2:32 Minutes",
        industry:"School Edtech Solutions",
        imgSrc: require("../data/images/portfolio/lead-school-pv27.webp"),
        imgAlt:"Lead school",
        videoURL:"https://www.youtube.com/embed/XflQZ8ZwJko"
    },
    {
        key:28,
        id: 28, 
        name:"HDFC Life",
        duration:"5:52 Minutes",
        industry:"Life Insurance Solutions Provider",
        imgSrc: require("../data/images/portfolio/hdfc-life-pv28.webp"),
        imgAlt:"HDFC Life",
        videoURL:"https://www.youtube.com/embed/AU7Nuc_lxHo"
    },
    {
        key:29,
        id: 29, 
        name:"Muskurahat Foundation",
        duration:"8:06 Minutes",
        industry:"Youth NGO in India",
        imgSrc: require("../data/images/portfolio/muskurahat-pv29.webp"),
        imgAlt:"Muskurahat Foundation",
        videoURL:"https://www.youtube.com/embed/0VlYDizRsNY"
    },
    {
        key:30,
        id: 30, 
        name:"Purplle",
        duration:"1:00 Minutes",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv30.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/gEBiQMo3cRk"
    },
    {
        key:31,
        id: 31, 
        name:"Aditya Birla Group",
        duration:"3:44 Minutes",
        industry:"Chemical (Birla Carbon)",
        imgSrc: require("../data/images/portfolio/aditya-birla-pv31.webp"),
        imgAlt:"Aditya Birla Group",
        videoURL:"https://www.youtube.com/embed/KMr0uVbnbwE"
    },
    {
        key:32,
        id: 32, 
        name:"Wonder cement",
        duration:"1:39 Minutes",
        industry:"Cement Manufacturing Producing",
        imgSrc: require("../data/images/portfolio/wonder-cement-pv32.webp"),
        imgAlt:"Wonder cement",
        videoURL:"https://www.youtube.com/embed/SrmO9CxZxRQ"
    },
    {
        key:33,
        id: 33, 
        name:"Upgrad",
        duration:" 2:03 Minutes",
        industry:"Online Educational Services",
        imgSrc: require("../data/images/portfolio/upgrad-pv33.webp"),
        imgAlt:"Upgrad",
        videoURL:"https://www.youtube.com/embed/FaR5DmM1whE"
    },
    {
        key:34,
        id: 34, 
        name:"EY",
        duration:" 3:29 Minutes",
        industry:"Health Insurance",
        imgSrc: require("../data/images/portfolio/ey-pv34.webp"),
        imgAlt:"EY",
        videoURL:"https://www.youtube.com/embed/n2rg8YO7I-o"
    },
    {
        key:35,
        id: 35, 
        name:"EY",
        duration:" 3:22 Minutes",
        industry:"Health Insurance",
        imgSrc: require("../data/images/portfolio/ey-pv35.webp"),
        imgAlt:"EY",
        videoURL:"https://www.youtube.com/embed/OpL-q0Fzmh4"
    },
    {
        key:36,
        id: 36, 
        name:"EY",
        duration:" 58 Seconds",
        industry:"Health Insurance",
        imgSrc: require("../data/images/portfolio/ey-pv36.webp"),
        imgAlt:"EY",
        videoURL:"https://www.youtube.com/embed/4_GOf3wtATQ"
    },
    {
        key:37,
        id: 37, 
        name:"EY",
        duration:"1:04 Minutes",
        industry:"Health Insurance",
        imgSrc: require("../data/images/portfolio/ey-pv37.webp"),
        imgAlt:"EY",
        videoURL:"https://www.youtube.com/embed/2HDBVFASYic"
    },
    {
        key:38,
        id: 38, 
        name:"EY",
        duration:"56 Seconds",
        industry:"Health Insurance",
        imgSrc: require("../data/images/portfolio/ey-pv38.webp"),
        imgAlt:"EY",
        videoURL:"https://www.youtube.com/embed/jY2FXmCk6kI"
    },
    {
        key:39,
        id: 39, 
        name:"Prudent Broker",
        duration:" 2:22 Minutes",
        industry:"Insurance Broker",
        imgSrc: require("../data/images/portfolio/prudent-broker-pv39.webp"),
        imgAlt:"Prudent Broker",
        videoURL:"https://www.youtube.com/embed/uIKdOcpByFY"
    },
    {
        key:40,
        id: 40, 
        name:"Quantiphi",
        duration:" 5:29 Minutes",
        industry:"Information Technology & Services",
        imgSrc: require("../data/images/portfolio/quantiphi-pv40.webp"),
        imgAlt:"Quantiphi",
        videoURL:"https://www.youtube.com/embed/6Y9PTjrV63M"
    },
    {
        key:41,
        id: 41, 
        name:"Novela films",
        duration:"26 Seconds",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/novela-films-pv41.webp"),
        imgAlt:"Novela films",
        videoURL:"https://www.youtube.com/embed/HX30GBqjFbU"
    },
    {
        key:42,
        id: 42, 
        name:"Yes bank",
        duration:"2:19 Minutes",
        industry:"Full Service Commercial Bank",
        imgSrc: require("../data/images/portfolio/yes-bank-pv42.webp"),
        imgAlt:"Yes bank",
        videoURL:"https://www.youtube.com/embed/m4Jl_DafDkw"
    },
    {
        key:43,
        id: 43, 
        name:"Uniapply",
        duration:"1:22 Minutes",
        industry:"School Application and Enrolment App",
        imgSrc: require("../data/images/portfolio/uniapply-pv43.webp"),
        imgAlt:"Uniapply",
        videoURL:"https://www.youtube.com/embed/6EjSmXUSmDk"
    },
    {
        key:44,
        id: 44, 
        name:"Bijak",
        duration:" 2:01 Minutes",
        industry:" IT Services and IT Consulting",
        imgSrc: require("../data/images/portfolio/bijak-pv44.webp"),
        imgAlt:"Bijak",
        videoURL:"https://www.youtube.com/embed/PjnsC-B0QT0"
    },
    {
        key:45,
        id: 45, 
        name:"Purplle",
        duration:"57 Seconds",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv45.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/PNb7wKjb8Cw"
    },
    {
        key:46,
        id: 46, 
        name:"Purplle",
        duration:"58 Seconds",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv46.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/BUziM46XR5E"
    },
    {
        key:47,
        id: 47, 
        name:"Flipkart Fashion",
        duration:" 51 Seconds",
        industry:"E-commerce Company",
        imgSrc: require("../data/images/portfolio/flipkart-pv47.webp"),
        imgAlt:"Flipkart Fashion",
        videoURL:"https://www.youtube.com/embed/jVO1qRH4Tns"
    },
    {
        key:48,
        id: 48, 
        name:"Purplle",
        duration:"59 Seconds",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv48.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/ak4WNgN8O5s"
    },
    {
        key:49,
        id: 49, 
        name:"Purplle",
        duration:"1:00 Minutes",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv49.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/g-EMcf73MF0"
    },
    {
        key:50,
        id: 50, 
        name:"Purplle",
        duration:"58 Seconds",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv50.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/ID6Qg7ehDSQ"
    },
    {
        key:51,
        id: 51, 
        name:"Purplle",
        duration:"1:00 Minutes",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv51.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/sweR4_CCmZg"
    },
    {
        key:52,
        id: 52, 
        name:"Purplle",
        duration:"1:14 Minutes",
        industry:"Online Shopping, Cosmetics",
        imgSrc: require("../data/images/portfolio/purplle-pv52.webp"),
        imgAlt:"Purplle",
        videoURL:"https://www.youtube.com/embed/l2INrxUyW6g"
    },
    {
        key:53,
        id: 53, 
        name:"Fincare Small Finance Bank",
        duration:"2:17 Minutes",
        industry:"Finance Bank",
        imgSrc: require("../data/images/portfolio/fincare-bank-pv53.webp"),
        imgAlt:"Fincare Small Finance Bank",
        videoURL:"https://www.youtube.com/embed/vIfx4TYZzxA"
    },
    {
        key:54,
        id: 54, 
        name:"StockHolding",
        duration:"6:33 Minutes",
        industry:"Corporate",
        imgSrc: require("../data/images/portfolio/stockholding-pv54.webp"),
        imgAlt:"StockHolding Corporation of India Limited",
        videoURL:"https://www.youtube.com/embed/caPkubDH1fg&t=166s"
    },
    {
        key:55,
        id: 55, 
        name:"Neogrowth",
        duration:"5:13 Minutes",
        industry:"Corporate",
        imgSrc: require("../data/images/portfolio/neogrowth-pv55.webp"),
        imgAlt:"Neogrowth",
        videoURL:"https://www.youtube.com/embed/RYGpwv__WvM"
    },
    {
        key:56,
        id: 56, 
        name:"Reliance Nippon - BAKU",
        duration:"5:13 Minutes",
        industry:"Life Insurance Solutions Provider",
        imgSrc: require("../data/images/portfolio/reliance-baku-pv56.webp"),
        imgAlt:"Reliance Nippon BAKU",
        videoURL:"https://www.youtube.com/embed/BRlK_1Vw498"
    },
    {
        key:57,
        id: 57, 
        name:"Tru Vision",
        duration:"5:13 Minutes",
        industry:"Electronics",
        imgSrc: require("../data/images/portfolio/truvision-pv57.webp"),
        imgAlt:"Truvision",
        videoURL:"https://www.youtube.com/embed/jnBuBiZ7XX4"
    },
    {
        key:58,
        id: 58, 
        name:"Depression - Balance your mind",
        duration:"46:11 Minutes",
        industry:"Documentary Video",
        imgSrc: require("../data/images/portfolio/balance-pv58.webp"),
        imgAlt:"Truvision",
        videoURL:"https://www.youtube.com/embed/Ss1DbXct3Sw"
    },
    {
        key:59,
        id: 59, 
        name:"Before We Actually Met - EPISODE 1 Voh Pehli Mulakat",
        duration:"25:00 Minutes",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/bwam-pv59.webp"),
        imgAlt:"Truvision",
        videoURL:"https://www.youtube.com/embed/q-4U5lC4tTU"
    },
    {
        key:60,
        id: 60, 
        name:"Before We Actually Met - EPISODE 2 Girlfriend-Boyfriend",
        duration:"25:00 Minutes",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/bwam-pv60.webp"),
        imgAlt:"Truvision",
        videoURL:"https://www.youtube.com/embed/q-4U5lC4tTU"
    },
    {
        key:61,
        id: 61, 
        name:"Before We Actually Met - EPISODE 3 Chai Pe Charcha",
        duration:"25:00 Minutes",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/bwam-pv61.webp"),
        imgAlt:"Truvision",
        videoURL:"https://www.youtube.com/embed/q-4U5lC4tTU"
    },
    {
        key:62,
        id: 62, 
        name:"Before We Actually Met - EPISODE 4 Non-Negotiables",
        duration:"25:00 Minutes",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/bwam-pv62.webp"),
        imgAlt:"Truvision",
        videoURL:"https://www.youtube.com/embed/q-4U5lC4tTU"
    },
    {
        key:63,
        id: 63, 
        name:"IFFCO TOKIO",
        duration:"16:23 Minutes",
        industry:"Insurance Company",
        imgSrc: require("../data/images/portfolio/ifco-tokyo-pv63.webp"),
        imgAlt:"Iffco tokio",
        videoURL:"https://www.youtube.com/embed/09vgY_RoobM"
    },
    {
        key:64,
        id: 64, 
        name:"LEAD SCHOOL - BODMAS",
        duration:"4:47 Minutes",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/lead-school-pv64.webp"),
        imgAlt:"Lead school",
        videoURL:"https://www.youtube.com/embed/w_sR5AgjSMA"
    },
    {
        key:65,
        id: 65, 
        name:"SIMPLILEARN",
        duration:"39 Seconds",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/simplilearn-pv65.webp"),
        imgAlt:"Simplilearn",
        videoURL:"https://www.youtube.com/embed/ZaH7GF62K7Y"
    },
    {
        key:66,
        id: 66,
        name:"MIT",
        duration:"12:53 Minutes",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/mit-becker-pv66.webp"),
        imgAlt:"MIT",
        videoURL:"https://www.youtube.com/embed/Y3EjKlPx-CI"
    },
    {
        key:67,
        id: 67,
        name:"BETTERPLACE",
        duration:"03:18 Minutes",
        industry:"Film Production",
        imgSrc: require("../data/images/portfolio/betterplace-pv67.webp"),
        imgAlt:"Betterplace",
        videoURL:"https://www.youtube.com/embed/bQuDwiSmf3s"
    },
    {
        key:101,
        id: 101, 
        name: "showreel by novela films", 
        imgSrc: require("../data/images/showreel-wt-md.webp"),
        imgAlt:"novela films private limited video production company showreel",
        videoURL:"https://www.youtube.com/embed/q-4U5lC4tTU"
    },
    {
        key:102,
        id: 102, 
        name:"services video by novela films",
        imgSrc: require("../data/images/services/services-bg-sm.webp"),
        imgAlt:"services offered by novela films private limited video production company",
        videoURL:"https://www.youtube.com/embed/z09zhTm8QDk"
    },
    {
        key:103,
        id: 103, 
        name:"process video by novela films",
        imgSrc: require("../data/images/process/process-bg.webp"),
        imgAlt:"video production process by novela films private limited video production company",
        videoURL:"https://www.youtube.com/embed/a9pi7X9bHkI"
    }
];

export default videos;