import React from "react";
import { Helmet } from "react-helmet-async";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import clientslistP from "../json/clientslistP";
import ClientPageCardS from "./ClientPageCardS";
import ClientPageCardE from "./ClientPageCardE";
import Layout from "../Layout/Dark";

function clientpageCardS(clientitem) {
    return (
        <ClientPageCardS
             key={clientitem.key}
            clientNAME={clientitem.clientNAME}
            clientIMG={clientitem.clientIMG}
            clientFEEDBACK={clientitem.clientFEEDBACK}
            clientPOSITION={clientitem.clientPOSITION}
            clientLINK={clientitem.clientLINK}
            clientBRANDIMG={clientitem.clientBRANDIMG}

        />
    );
}

function clientpageCardE(clientitem) {
    return (
        <ClientPageCardE
             key={clientitem.key}
            clientNAME={clientitem.clientNAME}
            clientIMG={clientitem.clientIMG}
            clientFEEDBACK={clientitem.clientFEEDBACK}
            clientPOSITION={clientitem.clientPOSITION}
            clientLINK={clientitem.clientLINK}
            clientBRANDIMG={clientitem.clientBRANDIMG}

        />
    );
}

function clientspage() {
    return (
        <>
        <Helmet>
        <title>Client Reviews - Novela Films</title>
        <link rel="canonical" href="https://novelafilms.com/clients/" />
        {/* Meta tags */}
        <meta name="description" content="Always give people more than what they expect to get." />
        <meta name="keywords" content="Client Reviews, Novela Films" />
        <meta property="og:title" content="Client Reviews - Novela Films" />
        <meta property="og:description" content="Always give people more than what they expect to get." />
        <meta property="og:image" content="https://novelafilms.com/novela-films.png" />
        </Helmet>

        <Layout>
            <Container fluid className="portfolio">
        <h4 style={{textAlign:"left"}}><a href="/">HOME </a>/ CLIENT REVIEWS</h4>
        <h1 className="quotes">“Always give people more than what they expect to get.”</h1>
        </Container>
            <Container fluid className="clients">
            <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="mt-5 mb-5">
                {clientslistP.map(clientpageCardS)[0]}      
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mt-5 mb-5">
                {clientslistP.map(clientpageCardE)[1]}      
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mt-5 mb-5">
                {clientslistP.map(clientpageCardS)[2]}      
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mt-5 mb-5">
                {clientslistP.map(clientpageCardE)[3]}      
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mt-5 mb-5">
                {clientslistP.map(clientpageCardS)[4]}      
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mt-5 mb-5">
                {clientslistP.map(clientpageCardE)[5]}      
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mt-5 mb-5">
                {clientslistP.map(clientpageCardS)[6]}      
                </Col>
            </Row>
            </Container>
        </Layout>
        </>
        )
}

export default clientspage