import React, { useState } from "react";
import videos from "../../json/videos";
import Container from 'react-bootstrap/Container';
import Resourcescard from "../Resources/Resourcescard";
// import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import "./OurVideos.css";
import VideoModal from "../Modal/VideoModal";
import Slider from "react-slick";
import PropTypes from 'prop-types';

function OurVideos(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [tempvideoSrc, setTempvideoSrc] = useState("");

  const openWindow = (videoSrc) => {
    setTempvideoSrc(videoSrc);
    setIsOpen(true);
  };

  const closeWindow = () => {
    setIsOpen(false);
  };

  const slider = React.useRef(null);
  const settings = {
    dots: false,
    dotsClass: "slick-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    cssEase: "linear",
    arrows:false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const { videoList } = props;
  const filteredVideos = videos.filter((video) => videoList.includes(video.id));

  return (
    <>
     <Container fluid className="our-videos">
    <div><h2 className="section-heading">Our Videos</h2></div>
      
    <Slider ref={slider} {...settings} className="our-videos-slider">
    {filteredVideos.map((item) => (
                <div key={item.id}  onClick={() => openWindow(item.videoURL)}
                style={{ cursor: "pointer" }} >
                  <Resourcescard
                    key={item.id}
                    blogNAME={item.name.toUpperCase()}
                    blogIMG={item.imgSrc}
                    imgDETAIL={item.imgAlt}
                  />
                </div>
              ))}
    </Slider>

    {/* <div className="slider-arrows">
    <FaArrowAltCircleLeft className="slider-prevarrow" name="previousarrow"
        onClick={() => slider?.current?.slickPrev()} />
    <FaArrowAltCircleRight className="slider-nextarrow" name="nextarrow" 
        onClick={() => slider?.current?.slickNext()} />
    </div> */}

    <div className="arrow-container">
          <div className="arrow arrow-left" name="previousarrow"
        onClick={() => slider?.current?.slickPrev()}>
            <div className="arrow-top"></div>
            <div className="arrow-bottom"></div>
          </div>
          <div className="arrow arrow-right" name="nextarrow" 
        onClick={() => slider?.current?.slickNext()} >
            <div className="arrow-top2"></div>
            <div className="arrow-bottom2"></div>
          </div>
    </div>
    
        {isOpen && ( <VideoModal videoUrl={tempvideoSrc}
        playing={true} closeButton={closeWindow} />
        )}
      </Container>
    </>
  );
}

OurVideos.propTypes = {
  videoList: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default  OurVideos;
