import React from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import blogList from "../../json/blogList";
import ErrorPage from "../ErrorPage";
import BlogPage1 from "./BlogList/BlogPage1";
import BlogPage2 from "./BlogList/BlogPage2";
import BlogPage3 from "./BlogList/BlogPage3";

function Blogs() {
  const { blogName } = useParams();
  const decodedBlogName = decodeURIComponent(blogName).replace(/-/g, " ");
  const blog = blogList.find((item) => item.blogName === decodedBlogName);

  if (!blog) {
    return (
      <>
        <ErrorPage />
        <h1>Error: Blog Not Found</h1>
        <h3 className="error-page__message">
          The requested blog could not be found. Please try again later.
        </h3>
      </>
    );
  }

 // Render the appropriate BlogPage component based on the blogId
 switch (blog.id) {
  case 1:
    return <BlogPage1 blog={blog} />;
  case 2:
    return <BlogPage2 blog={blog} />;
  case 3:
    return <BlogPage3 blog={blog} />;
  default:
    // If the blogId doesn't match any specific case, display an error
    return (
      <>
        <ErrorPage />
        <h1>Error: Blog Not Found</h1>
        <h3 className="error-page__message">
          The requested blog could not be found. Please try again later.
        </h3>
      </>
  );
}
}

export default Blogs;