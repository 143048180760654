import React from "react";
import { Helmet } from "react-helmet-async";
import processList from "../json/ourProcess";
import ServicesHerobg from "../components/OurServices/ServicesHero/ServicesHerobg";
import LightLayout from "../Layout/Light";
import OurProcess from "../components/OurProcess/OurProcess";

const vimal = processList.find((item) => item.key === 1);

function Processpage() {

    return (
      <>
        <Helmet>
        <title>Our Process - Novela Films</title>
        <link rel="canonical" href="https://novelafilms.com/process" />
        {/* Meta tags */}
        <meta name="description" content="Discover our creative and unique video production process." />
        <meta name="keywords" content="Our Process, Novela Films" />
        <meta property="og:title" content="Our Process - Novela Films" />
        <meta property="og:description" content="Discover our creative and unique video production process." />
        <meta property="og:image" content="https://novelafilms.com/novela-films.png" />
        </Helmet>

        <LightLayout>
        {processList.filter((item) => item.id === 2).map((item, index) => (
          <div key={index}>
          <ServicesHerobg
          key={item.id}
          heroIMG={item.pHeroImg}
          heroTITLE1={item.pHeroTitle1}
          heroTITLE2={item.pHeroTitle2}
          heroVideoUrl={item.pHeroVIDEOURL}
          googleIMG={item.pGoogleIMG}
          
          />
          </div>
        ))}

          <OurProcess
         opS1Title={vimal.opS1Title}
         opS1Text={vimal.opS1Text}
         opS1Img={vimal.opS1Img}
         opS1ImgAlt={vimal.opS1ImgAlt}

         opS2Title={vimal.opS2Title}
         opS2Text={vimal.opS2Text}
         opS2Img={vimal.opS2Img}
         opS2ImgAlt={vimal.opS2ImgAlt}

         opS3Title={vimal.opS3Title}
         opS3Text={vimal.opS3Text}
         opS3Img={vimal.opS3Img}
         opS3ImgAlt={vimal.opS3ImgAlt}

         opS4Title={vimal.opS4Title}
         opS4Text={vimal.opS4Text}
         opS4Img={vimal.opS4Img}
         opS4ImgAlt={vimal.opS4ImgAlt}

         opS5Title={vimal.opS5Title}
         opS5Text={vimal.opS5Text}
         opS5Img={vimal.opS5Img}
         opS5ImgAlt={vimal.opS5ImgAlt}

         opS6Title={vimal.opS6Title}
         opS6Text={vimal.opS6Text}
         opS6Img={vimal.opS6Img}
         opS6ImgAlt={vimal.opS6ImgAlt}

         opS7Title={vimal.opS7Title}
         opS7Text={vimal.opS7Text}
         opS7Img={vimal.opS7Img}
         opS7ImgAlt={vimal.opS7ImgAlt}

         opS8Title={vimal.opS8Title}
         opS8Text={vimal.opS8Text}
         opS8Img={vimal.opS8Img}
         opS8ImgAlt={vimal.opS8ImgAlt}

         />
           
</LightLayout>
      </>
    )
}

export default Processpage