import React from "react";
import { Container } from "react-bootstrap";

function BlogPage3({ blog }) {
  return (
    <Container>
      <h1>{blog.blogName}</h1>
      <img src={blog.blogImg} alt="b" style={{ width: "30%" }} />
      <p>{blog.blogText}</p>
      {/* Add unique design elements for Blog ID 1 */}
    </Container>
  );
}

export default BlogPage3;