const clients = [
    {
        key: 1,
        clientNAME: "Mitali Dharmani",
        clientIMG: require("../data/images/testimonials/mitali-dharmani.webp"),
        clientBRANDIMG: require("../data/images/companylogos/neogrowth-pl33.webp"),
        clientFEEDBACK:"We're delighted with Novela Films for their flawless execution, meticulous attention to detail, and superb coordination throughout the video creation process. The team's translation of our ideas into a captivating storyboard was impressive. The video perfectly encapsulated our brand's essence and effectively conveyed our message to the intended audience, garnering widespread appreciation. My heartfelt thanks to all involved for their valuable contributions.",
        clientPOSITION:"Senior Marketing Manager \n Neogrowth Credit",
        clientLINK:"https://www.linkedin.com/in/mitalidharmani/"
    },
    {
        key: 2,
        clientNAME: "Janki Raghavan",
        clientIMG: require("../data/images/testimonials/janki-raghavan.webp"),
        clientBRANDIMG: require("../data/images/companylogos/betterplace-pl32.webp"),
        clientFEEDBACK:"Thanks to Novela Films Pvt Ltd's efforts, the average rating of the courses was 4.2 out of 5 on the portal. The team responded positively to reviews and feedback and delivered everything according to the client's expectations. They converted a short brief into a workable script with good research. ",
        clientPOSITION:"Lead Design \n Betterplace",
        clientLINK:"https://www.linkedin.com/in/janki-raghavan-35b5a962/"
    },
    {
        key: 3,
        clientNAME: "Gaurav Wagh",
        clientIMG: require("../data/images/testimonials/gaurav-wagh.webp"),
        clientBRANDIMG: require("../data/images/companylogos/cl-mahindra.webp"),
        clientFEEDBACK:"The E-Alfa Mini explainer video came out better than I expected.\n\nThanks for the hardwork and effort Team Novela ! .",
        clientPOSITION:"Head of Marketing at Mahindra Electric Mobility Limited",
        clientLINK:"https://www.linkedin.com/in/waghgaurav/"

    },
    // {
    //     key: 2,
    //     clientNAME: "Ketan Muni",
    //     clientIMG: require("../data/images/testimonials/ketan-muni.webp"),
    //     clientBRANDIMG: require("../data/images/companylogos/cl-newindia.webp"),
    //     clientFEEDBACK:"Thanks, this was my second video with Novela Films.\n\nPlease accept our sincere gratitude in appreciation of these tireless efforts.\n\nIt has been a pleasure doing business with you.",
    //     clientPOSITION:"Head - IT & Operations at New India Co-Operative Bank Limited",
    //     clientLINK:"https://www.linkedin.com/in/ketan-muni-915510a6/"
    // },
    // {
    //     key: 3,
    //     clientNAME: "Biju Somnath",
    //     clientIMG: require("../data/images/testimonials/biju-somnath.webp"),
    //     clientBRANDIMG: require("../data/images/companylogos/cl-fincare.webp"),
    //     clientFEEDBACK:"Videos have been highly appreciated by the end users, Novela Films highly recommended.",
    //     clientPOSITION:"Vice President Marketing at Fincare Small Finance Bank",
    //     clientLINK:"https://www.linkedin.com/in/biju-somnath-55ba4810/"
    // },
    {
        key: 4,
        clientNAME: "Anubhav Banga",
        clientIMG: require("../data/images/testimonials/anubhav-banga.webp"),
        clientBRANDIMG: require("../data/images/companylogos/cl-uniapply.webp"),
        clientFEEDBACK:"Extremely satisfied with the video output from Novela Films.\n\n Communication was very good throughout the process.\n\n Highly recommended.",
        clientPOSITION:"Brand Manager at Uniapply Company",
        clientLINK:"https://linkedin.com/in/anubhav-banga-0621254a"
    },
    {
        key: 5,
        clientNAME: "Shalaka Chawande",
        clientIMG: require("../data/images/testimonials/shalaka-chawande.webp"),
        clientBRANDIMG: require("../data/images/companylogos/cl-reliance.webp"),
        clientFEEDBACK:"Well done team...\n\n Appreciate all the efforts taken to close the job on time and without compromising quality.\n\n Please accept my sincere thanks for all the efforts you all put in at an need of an hour.",
        clientPOSITION:"Channel Marketing Specialist at Reliance Nippon Life Insurance",
        clientLINK:"https://www.linkedin.com/in/shalaka-chawande-45303411/"
    },
    {
        key: 6,
        clientNAME: "Sanket Sangawar",
        clientIMG: require("../data/images/testimonials/sanket-sangawar.webp"),
        clientBRANDIMG: require("../data/images/companylogos/cl-hdfc.webp"),
        clientFEEDBACK:"Highly professional and skilled team @ Novela Films.\n\n It was a very pleasant experience working with Yash & team.",
        clientPOSITION:"Senior Manager at Bancassurance HDFC Life",
        clientLINK:"https://linkedin.com/in/sanket-sangawar-75278a34"
    },
    {
        key: 7,
        clientNAME: "Ayush Nathani",
        clientIMG: require("../data/images/testimonials/ayush-nathani.webp"),
        clientBRANDIMG: require("../data/images/companylogos/cl-oxedent.webp"),
        clientFEEDBACK:"I am very happy with the video quality and the overall execution done by your team while keeping our expectations aligned seamlessly with this project.",
        clientPOSITION:"Head of Operations at Oxedent",
        clientLINK:"https://www.linkedin.com/in/ayushpreneur/"
    },
    
];

export default clients;