import React from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ServicesHerobg from "./ServicesHero/ServicesHerobg";
import serviceslist from "../../json/serviceslist";
import { Col, Container, Row } from 'react-bootstrap';
import "./ChildService.css";
import DarkLayout from "../../Layout/Dark";
const ErrorPage = React.lazy(() => import("../ErrorPage"));
const WhyNovela = React.lazy(() => import("../WhyNovela/WhyNovela"));
const OurWork = React.lazy(() => import("../OurWork/OurWork"));
const OurProcess = React.lazy(() => import("../OurProcess/OurProcess"));
const Testimonials = React.lazy(() => import("../Testimonial/Testimonial"));
const Partners = React.lazy(() => import("../Partners"));
const OurEbooks = React.lazy(() => import("../OurEbooks/OurEbooks"));
const OurVideos = React.lazy(() => import("../OurVideos"));
const LazyPartnerLogo = React.lazy(() => import("../PartnerLogo"));
const LazyContactForm = React.lazy(() => import("../Contact"));

const ChildService = () => {

const { serviceName } = useParams();
const decodedServiceName = decodeURIComponent(serviceName).replace(/-/g, " ");
const service = serviceslist.find((item) => item.serviceNAME === decodedServiceName);

//const serviceID = service ? service.id : null; /* To match id with list id*/
const filteredSL = []

//First Letter Capital Casing
const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .replace(/^(.)|\s(.)/g, (char) => char.toUpperCase());
};

if (!service || filteredSL.includes(service.id)) {
  return (
    <DarkLayout>
        <ErrorPage />
        <h1>Error: Service Not Found</h1>
        <h3 className="error-page__message">
          The requested service could not be found. Please try again later.
        </h3>
    </DarkLayout>
  );  
  }
  
  return (
    <>
        {/* HELMET FOR SEO */}
        <Helmet>
          <title>{toTitleCase(service.serviceNAME)} - Novela Films</title>
          <link rel="canonical" href={service.serviceLink} />
          {/* Meta tags */}
          <meta name="description" content={service.serviceDESC} />
          <meta name="keywords" content={service.serviceKeywords} />
          <meta property="og:title" content={toTitleCase(service.serviceNAME)} />
          <meta property="og:description" content={service.serviceDESC} />
          <meta property="og:image" content={service.serviceIMG} />
        </Helmet>
        <ServicesHerobg
          heroIMG={service.heroIMG}
          heroIMG_SM={service.heroIMG_SM}
          heroIMG_MD={service.heroIMG_MD}
          heroIMG_LG={service.heroIMG_LG}
          heroIMGDETAIL={service.heroIMGDETAIL}
          heroTITLE1={service.heroTITLE1}
          heroTITLE2={service.heroTITLE2}
          heroTITLE3={service.heroTITLE3}
          heroVideoUrl={service.heroVIDEOURL}
          />
        <Partners />
        <Container fluid className='service-desc'>
            <div><p className='desc-text1'>{service.descTEXT1}
            <span>{service.descTEXT2}</span></p></div>
            <hr id='hr-service' />
            <div><h2 className='desc-text2'>{service.descTEXT3}</h2></div>
            <div className='desc-text3'><p>{service.descTEXT4}</p></div>
            <div className='desc-text3'><p>{service.descTEXT5}</p></div>
            <Row>
              <Col md={6} sm={12} className="">
              {/* {serviceslist
              .filter((service) => service.key === serviceId)
              .map((item, index) => (
                <li key={index}>{item.descTEXT5}</li>))} */}
                {service.descTEXT6 && (
                <ul className="desc-text4">
                  {service.descTEXT6.map((item, index) => (
                    <li key={index}>
                    {item.title}<span> {item.description}</span>
                    </li>
                  ))}
                </ul>
                )}
              </Col>
              <Col md={6} sm={12}>
              <picture>
              <source srcSet={service.descIMG_SM} media="(max-width: 375px)" />
              <source srcSet={service.descIMG_MD} media="(max-width: 576px)" />
              <img style={{height:"100%", width:"100%"}} src={service.descIMG} 
              alt={service.descIMGALT} className='desc-img' />
              </picture>
              </Col>
            </Row>
            <div className='desc-text5'><p>{service.descTEXT7}<span>{service.descTEXT8}</span></p></div>
            <div className='desc-text6'><p>{service.descTEXT9}</p></div>
        </Container>
        <hr id='hr-service2' />
        <WhyNovela 
          wcnEXP={service.wcnEXP}
          wcnFD={service.wcnFD}
          wcnQC={service.wcnQC}
          wcnSO={service.wcnSO}
          wcnTOE={service.wcnTOE}
          wcnTP={service.wcnTP}
        />
        <Container fluid className="flag mb-0">
        <div><p className="flag-text1">READY?</p></div>
        <div><p className="flag-text2">Let’s {service.flagText}</p></div>
        <div><p className="flag-text3">Our team will be happy to bring your ideas to life.</p></div>
        <div>
              <Link to="/contact/" className="flag_quote_button">
              <span className="flag_quote_button_text">GET QUOTE</span>
              <span className="flag_quote_button_fill"></span>
              </Link>
        </div> 
        </Container> 
        <OurWork
         owTEXT={service.owTEXT}
         videoList={service.videoList}
         />
         <OurProcess
         opS1Title={service.opS1Title}
         opS1Text={service.opS1Text}
         opS1Img={service.opS1Img}
         opS1ImgAlt={service.opS1ImgAlt}

         opS2Title={service.opS2Title}
         opS2Text={service.opS2Text}
         opS2Img={service.opS2Img}
         opS2ImgAlt={service.opS2ImgAlt}

         opS3Title={service.opS3Title}
         opS3Text={service.opS3Text}
         opS3Img={service.opS3Img}
         opS3ImgAlt={service.opS3ImgAlt}

         opS4Title={service.opS4Title}
         opS4Text={service.opS4Text}
         opS4Img={service.opS4Img}
         opS4ImgAlt={service.opS4ImgAlt}

         opS5Title={service.opS5Title}
         opS5Text={service.opS5Text}
         opS5Img={service.opS5Img}
         opS5ImgAlt={service.opS5ImgAlt}

         opS6Title={service.opS6Title}
         opS6Text={service.opS6Text}
         opS6Img={service.opS6Img}
         opS6ImgAlt={service.opS6ImgAlt}

         opS7Title={service.opS7Title}
         opS7Text={service.opS7Text}
         opS7Img={service.opS7Img}
         opS7ImgAlt={service.opS7ImgAlt}

         opS8Title={service.opS8Title}
         opS8Text={service.opS8Text}
         opS8Img={service.opS8Img}
         opS8ImgAlt={service.opS8ImgAlt}

         />
         <Testimonials />
         <OurEbooks />
         <OurVideos
          videoList={service.sliderVL}
          />
         <Container fluid className="flag">
        <div><p className="flag-text1">READY?</p></div>
        <div><p className="flag-text2">Let’s {service.flag2Text}</p></div>
        <div><p className="flag-text3">Our team will be happy to bring your ideas to life.</p></div>
        <div>
            <Link to="/contact/" className="flag_quote_button">
            <span className="flag_quote_button_text">GET QUOTE</span>
            <span className="flag_quote_button_fill"></span>
            </Link>
        </div> 
        </Container>
        <LazyPartnerLogo />
        <hr id='hr-service2' />
        <LazyContactForm />
    </>
  )
}

export default ChildService