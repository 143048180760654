import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedin, faYoutube} from "@fortawesome/free-brands-svg-icons";
import WhatsAppbtn from "../WhatsAppbtn";
import Top from "../Top";
import "./Footer.css"

function Footer() {

const [counterOn, setCounterOn] = useState(false);
const currentYear = new Date().getFullYear();

return (
    <>
    <Container fluid className="footer">
        <div className="footer-flag">
            <hr className="footer-hr" />
            <p>We're changing the way the Business communicates, 
            We make it happen!</p>
            <hr className="footer-hr" />
        </div>
        
         <div className="footer-link-items">
            <Row>
            <Col lg={6} md={6} sm={6} xs={12}>
            <ul className="list-unstyled footer-link-text footer-center-align ">
            <li><p className="footer-section-heading">OUR SERVICES</p></li>
            <li><a href="/services/2d-animation-explainer">2D ANIMATION EXPLAINER</a></li>
            <li><a href="/services/motion-graphics">MOTION GRAPHICS</a></li>
            <li><a href="/services/mobile-explainer">MOBILE EXPLAINER</a></li>
            <li><a href="/services/corporate-films">CORPORATE FILMS</a></li>
            <li><a href="/services/corporate-interviews">CORPORATE INTERVIEWS</a></li>
            <li><a href="/services/video-editing">VIDEO EDITING</a></li>
            <li><a href="/services/product-videos">PRODUCT VIDEOS</a></li>
            <li><a href="/services/educational">EDUCATIONAL</a></li>
            <li><a href="/services/web-series">WEB SERIES</a></li>
            </ul>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
            <ul className="list-unstyled footer-link-text footer-center-align ">
            <li><p className="footer-section-heading">NAVIGATIONS</p></li>
            <li><a href="/services">SERVICES</a></li>
            <li><a href="/gallery">GALLERY</a></li>
            <li><a href="/portfolio">PORTFOLIO</a></li>
            <li><a href="/resources">BLOG</a></li>
            <li><a href="/process">PROCESS</a></li>
            <li><a href="/resources">RESOURCES</a></li>
            <li><a href="/contact">CONTACT</a></li>
            </ul>
            </Col>
            </Row>
        </div>                

            <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(true)}>
            <Row className="mt-5 mb-5 footer-counter">
                <Col lg={4} md={4} sm={6}>
                <h3 className="footer-counter-title"><span className="footer-span">
                {counterOn && <CountUp start={0} end={501} duration={1} delay={0}/>}+
                </span> Completed Projects</h3>      
                </Col>

                <Col lg={4} md={4} sm={6}>
                <h3 className="footer-counter-title"><span className="footer-span">&nbsp;&nbsp;&nbsp;&nbsp;
                {counterOn && <CountUp start={0} end={10} duration={1} delay={0}/>}+
                </span> Total Million Views</h3>
                </Col>

                <Col lg={4} md={4} sm={12}>
                <h3 className="footer-counter-title"><span className="footer-span">&nbsp;&nbsp;&nbsp;
                {counterOn && <CountUp start={0} end={25} duration={1} delay={0}/>}+
                </span> Total Team</h3>                  
                </Col>

            </Row>
            </ScrollTrigger>

            <div>
            <a href="https://www.linkedin.com/company/novela-films/mycompany/" target="_blank" rel="noopener noreferrer" title="linkedin">
            <FontAwesomeIcon icon={faLinkedin} className="foo-logo" /></a>
            <a href="https://www.youtube.com/channel/UCU678vumQHBerlq9E7Ov2Dg" target="_blank" rel="noopener noreferrer" title="youtube">
            <FontAwesomeIcon icon={faYoutube} className="foo-logo" /></a>
            <a href="https://www.instagram.com/novelafilmsofficial/" target="_blank" rel="noopener noreferrer" title="instagram">
            <FontAwesomeIcon icon={faInstagram} className="foo-logo" /></a>
            <a href="https://www.facebook.com/NovelaFilmsMumbai" target="_blank" rel="noopener noreferrer" title="facebook">
            <FontAwesomeIcon icon={faFacebookF} className="foo-logo" /></a>
            </div>

            <div className="footer-copyright">© 2017-{currentYear} Novela Films Private Limited</div>
        </Container>
        <WhatsAppbtn />
        <Top />
        </>
           
    )
}

export default Footer