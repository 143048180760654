import React from "react";
import { Container } from "react-bootstrap";
import IframeResizer from "iframe-resizer-react";

function BlogPage1({ blog }) {

  const wordpressPageURL = "https://novelafilms.com/blogss/index.php/testimonials/";

  return (
    <>
    <Container>
        <h1>{blog.blogName}</h1>
        <img src={blog.blogImg} alt="a" style={{ width: "30%" }} />
        <p>{blog.blogText}</p>
      </Container>
      <IframeResizer
          log
          src={wordpressPageURL}
          style={{
            width: "100%",
            height: "800px",
            border: "none",
          }}
        />
    </>
    
  );
}

export default BlogPage1;