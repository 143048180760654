import React from "react";
import Container from "react-bootstrap/Container";

function Aboutus() {
    return (
        <Container fluid className="colored-section about">
            <h3 className="about-title">Novela Films Private Limited is a full-service creative video 
            content studio, with a focus on marketing and branding for corporate clients across 
            various industries in India & Abroad.<br/><br/>Novela Films Private Limited offers a wide range of services right from Ideation, 
            Creative conceptualization, Scripting, Visualization, Story Boarding, Mood Boarding, 
            Production, Line Production and Post-production.</h3>
        </Container>
    )
}

export default Aboutus