import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import serviceslist from "../../json/serviceslist";
import { Link } from "react-router-dom";
import "./ServicesCard.css";

function Servicecard(props) {

    return ( 
        <Container fluid className="mt-5 mb-5 p-0">
          <Row className="mb-5">
            {serviceslist
              .filter((service) => service.key >= 1 && service.key <= 9)
              .map((service, index) => (
                <Col
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  key={index}
                  className='g-0'
                >
                <Link to={`/services/${encodeURIComponent(service.serviceNAME).replace(/%20/g, '-')}`}>
                <div className={service.key % 2 === 0 ? "services-card-even" : "services-card-odd"} 
                style={{cursor : 'pointer'}} >
                <img src={service.serviceIMG} alt={service.serviceIMGALT} 
                 className={props.serviceCardImg} />
                <div><h2 className="services-card-img-text">{service.serviceNAME.toUpperCase()}</h2></div>
                </div>
                  </Link>
                </Col>
              ))}
          </Row>
        </Container>
      );
    }
export default Servicecard
