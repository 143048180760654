import React from "react";
import { Helmet } from "react-helmet-async";
import Resources from "../components/Resources";
import DarkLayout from "../Layout/Dark";

function Resourcespage() {
    return (
        <>
        <Helmet>
      <title>Our Resources - Novela Films</title>
      <link rel="canonical" href="https://novelafilms.com/resources" />
      {/* Meta tags */}
      <meta name="description" content="Always give people more than what they expect to get." />
      <meta name="keywords" content="Resources, Novela Films" />
      <meta property="og:title" content="Resources - Novela Films" />
      <meta property="og:description" content="Always give people more than what they expect to get." />
      <meta property="og:image" content="https://novelafilms.com/novela-films-logo.png" />
      </Helmet>

    <DarkLayout>
        <Resources />
    </DarkLayout>
    </>
            
    )
}

export default Resourcespage