import React, { useState } from "react";
import "./DarkNavigationBar.css";
import ToggleMenu from "../Navigation/ToggleMenu";
import { Link } from "react-router-dom";
import ServicesMenu from "../Navigation/ServicesMenu";
import { FaCaretDown } from "react-icons/fa";

function NavigationBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggleDropdown = (e) => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className={`dnavbar ${isScrolled ? "scrolled" : ""}`}>
      <div>
        <a href="/" className="dnavbar_logo_text">
        <picture>
          <source srcSet={require("../../data/images/novela-films-logo-sm.webp")} 
          media="(max-width: 320px)" />
          <img alt="novela-films-company-logo"
            src={require("../../data/images/novela-films-logo-default.webp")}
            className="dnavbar_logo_img"
          />
        </picture>
        &nbsp;Novela Films
        </a>
      </div>
      <ul className="dnavbar__links ms-auto">
      <li><Link className={`dnavbar__link ${isDropdownOpen ? "rotate-icon" : ""}`}
      onClick={handleToggleDropdown}>
          SERVICES{" "}<FaCaretDown className="caret-icon" 
           />
          </Link>
          {isDropdownOpen && (
            <ServicesMenu
              isOpen={isDropdownOpen}
              onClose={() => setIsDropdownOpen(false)}
            />
          )}
        </li>
        <li><Link to="/portfolio" className="dnavbar__link">PORTFOLIO</Link></li>
        <li><Link to="/clients" className="dnavbar__link">CLIENT REVIEWS</Link></li>
        <li><Link to="/resources" className="dnavbar__link">RESOURCES</Link></li>
        <li><Link to="/contact" className="dnavbar_button">
            <span className="dnavbar_button_text">GET QUOTE</span>
            <span className="dnavbar_button_fill"></span>
          </Link>
        </li>
        <li className="">
          <ToggleMenu />
        </li>
      </ul>
    </nav>
  );
}

export default NavigationBar;
