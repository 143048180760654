import React, { useState } from "react";
import "./ToggleMenu.css";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

function ToggleMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className={`toggle-menu ${isOpen ? "open" : ""}`}>
      <div className="toggle-menu-button" onClick={toggleMenu}>
      <FaBars className="toggle-menu-icon" />
      </div>
     
      <ul className="toggle-menu-items">
      {isOpen && (
        <>
        <div className="toggle-menu-logo">
        <Link to="/" className="toggle-menu-logo-text">
        <picture>
          <source srcSet={require("../../data/images/novela-films-logo-sm.webp")} 
          media="(max-width: 320px)" />
          <img
            alt="novela-films-company-logo"
            src={require("../../data/images/novela-films-logo-default.webp")}
            className="navbar_logo_img"
          />
        </picture>
        &nbsp;Novela Films
        </Link>
        </div>

        <div className="toggle-menu-close" onClick={closeMenu}>
        <FaTimes className="toggle-menu-close-icon" />
        </div>
        </>
      )}
        <li className="toggle-menu-item"><Link to="/" className="toggle-menu-item-text">HOME</Link></li>
        <li className="toggle-menu-item"><Link to="/services" className="toggle-menu-item-text">SERVICES</Link></li>
        <li className="toggle-menu-item"><Link to="/portfolio" className="toggle-menu-item-text">PORTFOLIO</Link></li>
        <li className="toggle-menu-item"><Link to="/process" className="toggle-menu-item-text">OUR PROCESS</Link></li>
        <li className="toggle-menu-item"><Link to="/clients" className="toggle-menu-item-text">CLIENT REVIEWS</Link></li>
        <li className="toggle-menu-item"><Link to="/resources" className="toggle-menu-item-text disabled-link">BLOGS</Link></li>
        <li className="toggle-menu-item"><Link to="/resources" className="toggle-menu-item-text">RESOURCES</Link></li>
        <li className="toggle-menu-item"><Link to="/gallery" className="toggle-menu-item-text">GALLERY</Link></li>
        <li className="toggle-menu-item"><Link to="/contact" className="toggle-menu-item-text">GET QUOTE</Link></li>
      </ul>
    </div>
  );
}

export default ToggleMenu;
