import React from "react";
import resourceslist from "../../json/resourceslist";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import OurVideos from "../OurVideos";

function Resources() {

const videos = resourceslist.find((item) => item.id === 7);

  return (
    <>
      <Container fluid className="p-0 ps-2 pe-2 mt-5 mb-5">
        <h2 className="section-heading mb-5">E-Books</h2>
        <Container fluid className="mt-3">
          <Row className="mb-5">
            {resourceslist
              .filter((ebook) => ebook.key >= 4 && ebook.key <= 6)
              .map((ebook, index) => (
                <Col
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  key={index}
                >
                <Link to={`/resources/ebook-download/${encodeURIComponent(ebook.blogNAME).replace(/%20/g, '-')}`}>
                <div key={index}>
                <img 
                className="card-b"
                style={{ height: "100%", width: "75%" }}
                src={ebook.blogIMG}
                alt={ebook.imgDETAIL}
                />
                </div>
                  </Link>
                </Col>
              ))}
          </Row>
        </Container>
      </Container>
      <OurVideos
          key={videos.id}
          videoList={videos.sliderVL} />
    </>
  );
}

export default Resources;
