import React from 'react'

function ErrorPage() {
  return (
    <div className="error-page">
      <h1 className="error-page__title">Oops!</h1>
      <p className="error-page__message">Something went wrong.</p>
      <p className="error-page__message">Please try again later.</p>
      <picture>
        <source srcSet={require("../data/images/novela-films-logo-sm.webp")} 
        media="(max-width: 320px)" />
        <img className="error-page__image"
        src={require("../data/images/novela-films-logo-default.webp")}
        alt="novela films website error page"
      />
        </picture>
      <br/><br/>
      <a href='/' className="error-page__title">Home Page</a>
    </div>
  )
}

export default ErrorPage