import React, {useState} from 'react';
import "./ServicesHerobg.css";
import { Col, Container, Row } from 'react-bootstrap';
import { FaStar, FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import VideoModal from '../../Modal/VideoModal';

function ServicesHerobg (props) {

  const [isOpen, setIsOpen] = useState(false);

  const openWindow = () => {
    setIsOpen(true);
  };

  const closeWindow = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="services-video-bg">
      <picture>
      <source srcSet={props.heroIMG_SM} media="(max-width: 576px)" />
      <source srcSet={props.heroIMG_MD} media="(max-width: 768px)" />
      <source srcSet={props.heroIMG_LG} media="(max-width: 992px)" />
      <img src={props.heroIMG} alt={props.heroIMGDETAIL} 
      className='services-video-bg-img' />
      </picture>
      <div className="overlay"></div>
      <Container fluid className='services-hero-container'>
            <Row>
              <Col md={6} xs={12} className='p-0'>
              {/* Hero Title */}
              <div className='services-hero-title-container'>
                <h2 className='services-hero-title1'>{props.heroTITLE1}<br/>
                <span style={{color:"#FFCD3C"}}>{props.heroTITLE2}</span><br/>
                {props.heroTITLE3}</h2><br/>
                <h3 className='services-hero-title2'>Let's create a video</h3><br/>
              </div>
              {/* Hero Quote Button */}
              <div className='hero-quote'>
              <Link to="/contact/" className="services-hero_quote_button">
              <span className="services-hero_quote_button_text">GET QUOTE</span>
              <span className="services-hero_quote_button_fill"></span>
              </Link>
              </div>              
              {/* Hero Rating */}
              <div className='hero-rating'>
              <a href="https://www.google.com/search?q=novela+films" 
              target="_blank" rel="noopener noreferrer" className="services-hero-rating-button"> 
              <img style={{height:"100%", width:"100%"}}
               src={require("../../../data/images/google.webp")} 
               alt='google-logo' className='services-hero-rating-logo' />
              {Array(5).fill().map((_, index) => (<FaStar key={index} className='services-hero-rating-star' />))}
              <p>Rating 4.7</p></a>
              <a href="https://clutch.co/profile/novela-films#highlights" 
              target="_blank" rel="noopener noreferrer" className="services-hero-rating-button"> 
              <img style={{height:"100%", width:"100%"}}
               src={require("../../../data/images/clutch.webp")} 
               alt='google-logo' className='services-hero-rating-logo' />
              {Array(5).fill().map((_, index) => (<FaStar key={index} className='services-hero-rating-star' />))}
              <p>Rating 5.0</p></a>
              </div>
              </Col>

              <Col md={6} xs={12} className='services-hero-play p-0'>
              {/* Hero Play Button */}
                <button id='shpb' title='watch video' className='services-hero-play-button' 
                onClick={openWindow}>
                <span className="services-hero-play-button_text">
              <FaPlay className=''/></span>
              <span className="services-hero-play-button_fill"></span>
              </button>
              <p>WATCH VIDEO</p>
              </Col>
            </Row>
      </Container>
              {isOpen && ( <VideoModal videoUrl={props.heroVideoUrl} 
                playing={true}
                closeButton={closeWindow} /> )}
        </div>
    </>
  )
}

export default ServicesHerobg