import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedin} from "react-icons/fa";
import "./ContactForm.css";


function ContactForm() {
  return (
    <>
      <Container fluid className="contacts">
      <p className="contacts-header">Say Hello <span className="wave">👋</span></p>            
      <Row>
          <Col md={6} sm={12}  className='contacts-items'>
          <ul className="list-unstyled contacts-items-text">
              <li>We are a leading Video Agency that connects brands and people 
              through <strong>customized video content.</strong></li>
              <li>Trusted by global brands, from startups to Fortune 500 companies.</li>
              <li>Our creative team crafts captivating videos, tailored to each brand's unique vision. Let us help boost brand awareness, enhance engagement, and bring your creative ideas to life.</li>
              <li >Collaborative and committed, we tell your story with excellence, making a lasting impression in the digital landscape.</li>
              <li>Join us to explore limitless possibilities in visual storytelling. Let's create together and leave a mark on the world.</li>
          </ul>
          <div className='contact-social-items'>
              <h3><strong>Follow Us</strong></h3><br/>
              <span className='contact-social-item linkedin'>
              <a href="https://www.linkedin.com/company/novela-films/mycompany/" target="_blank" rel="noopener noreferrer" title="linkedin">
              <FaLinkedin className="contact-social-logo" /></a></span>
              <span className='contact-social-item youtube'>
              <a href="https://www.youtube.com/channel/UCU678vumQHBerlq9E7Ov2Dg" target="_blank" rel="noopener noreferrer" title="youtube">
              <FaYoutube className="contact-social-logo" /></a></span>
              <span className='contact-social-item instagram'>
              <a href="https://www.instagram.com/novelafilmsofficial/" target="_blank" rel="noopener noreferrer" title="instagram">
              <FaInstagram className="contact-social-logo" /></a></span>
              <span className='contact-social-item facebook'>
              <a href="https://www.facebook.com/NovelaFilmsMumbai" target="_blank" rel="noopener noreferrer" title="facebook">
              <FaFacebookF className="contact-social-logo" /></a></span>
          </div>
          </Col>

          <Col md={6} sm={12} className='form-items'>
          <iframe title='contact-form' className='contacts-form'
          src='https://forms.novelafilms.com/nf_forms/form/ContactUs/formperma/Pp-Wqg7c4I49xYU7a8u6Qu1O-J-aIVj-h5guZNWtaVA'></iframe>
          </Col>
      </Row>
      </Container>
      {/* <hr id='contacts-hr' /> */}
    </>
  )
}

export default ContactForm