import React from "react";
import Container from "react-bootstrap/Container";

function Aboutservices() {
    return (
        <Container fluid className="colored-section abouts">
        <h3 className="abouts-title">Producing high-caliber video content including 
        branded promotional content, digital add films, animation videos, corporate style 
        interviews, croma shoots, product videos, webseries and feature films</h3>
        </Container>
    )
}

export default Aboutservices