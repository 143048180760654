import React from "react";
import { Row, Col } from "react-bootstrap";
import serviceslist from "../../../json/serviceslist";
import "./ServicesMenu.css";

const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .replace(/^(.)|\s(.)/g, (char) => char.toUpperCase());
};

function ServicesMenu({ isOpen, onClose }) {
  return (
    <div className={`services-menu ${isOpen ? "open" : ""}`}>
    <div className="services-menu__list" >
    <p className="services-menu__title ms-4 me-4 mb-5">
    What we bring to the table.
    <hr id="service-menu-hr"/></p>
    </div>
      <Row xl={3} lg={3} md={3} className="services-menu__list" >
        {serviceslist.filter((option) => option.key <= 9).map((option, index) => (
          <a href={`/services/${encodeURIComponent(option.serviceNAME).replace(/%20/g, '-')}`}>
          <Col key={index} className="services-menu__item">
          <div className="services-menu__title">{toTitleCase(option.serviceNAME)}</div>
          <div className="services-menu__text">{option.serviceDESC}</div>
          </Col>
          </a>
        ))}
      </Row>
    </div>
  );
}

export default ServicesMenu;
