import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaCircle } from 'react-icons/fa';
import './OurProcess.css';

const OurProcess = (props) => {
  return (
    <>
      <Container fluid className="flag mt-0">
        <div><p className="flag-text1">OUR PROCESS</p></div>
        <div><p className="flag-text2">How do we do things</p></div>
        <div><p className="flag-text3"> We have a simple streamlined process.</p></div>
      </Container>
      <div><p className='section-heading'>8 Step Process</p></div>
      <div className="timeline">
        {/* <FaCircle className='dot-top' /> */}
        <div className="timeline-dot">
          <FaCircle className='dot-top' />
          <FaCircle className='dot-btm' />
        </div>

        <div className='timeline-item'>
          <div className="timeline-dot">
            <FaCircle className='dot-middle' />
          </div>
          <Row>
            <Col lg={6} md={6} className='timeline-item-image'>
              <h2 className='left-title'>{props.opS1Title}</h2>
              <img style={{height:"100%", width:"100%"}}
               src={props.opS1Img} alt={props.opS1ImgAlt} />
            </Col>
            <Col lg={6} md={6} className='timeline-item-text'>
              <p className='timeline-text'>{props.opS1Text}</p>
            </Col>
          </Row>
        </div>

        <div className='timeline-item'>
          <div className="timeline-dot">
            <FaCircle className='dot-middle' />
          </div>
          <Row>
            <Col xl={{ span: 6, order: 0 }} md={{ span: 6, order: 0 }}
              sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}
              className='timeline-item-text'>
              <p className='timeline-text'>{props.opS2Text}</p>
            </Col>
            <Col xl={{ span: 6, order: 1 }} md={{ span: 6, order: 1 }}
              sm={{ span: 12, order: 0 }} xs={{ span: 12, order: 0 }}
              className='timeline-item-image'>
              <h2 className='right-title'>{props.opS2Title}</h2>
              <img style={{height:"100%", width:"100%"}}
               src={props.opS2Img} alt={props.opS2ImgAlt} />
            </Col>
          </Row>
        </div>

        <div className='timeline-item'>
          <div className="timeline-dot">
            <FaCircle className='dot-middle' />
          </div>
          <Row>
            <Col lg={6} md={6} className='timeline-item-image'>
              <h2 className='left-title'>{props.opS3Title}</h2>
              <img style={{height:"100%", width:"100%"}}
               src={props.opS3Img} alt={props.opS3ImgAlt} />
            </Col>
            <Col lg={6} md={6} className='timeline-item-text'>
              <p className='timeline-text'>{props.opS3Text}</p>
            </Col>
          </Row>
        </div>

        <div className='timeline-item'>
          <div className="timeline-dot">
            <FaCircle className='dot-middle' />
          </div>
          <Row>
            <Col xl={{ span: 6, order: 0 }} md={{ span: 6, order: 0 }}
              sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}
              className='timeline-item-text'>
              <p className='timeline-text'>{props.opS4Text}</p>
            </Col>
            <Col xl={{ span: 6, order: 1 }} md={{ span: 6, order: 1 }}
              sm={{ span: 12, order: 0 }} xs={{ span: 12, order: 0 }}
              className='timeline-item-image'>
              <h2 className='right-title'>{props.opS4Title}</h2>
              <img style={{height:"100%", width:"100%"}}
               src={props.opS4Img} alt={props.opS4ImgAlt} />
            </Col>
          </Row>
        </div>

        <div className='timeline-item'>
          <div className="timeline-dot">
            <FaCircle className='dot-middle' />
          </div>
          <Row>
            <Col lg={6} md={6} className='timeline-item-image'>
              <h2 className='left-title'>{props.opS5Title}</h2>
              <img style={{height:"100%", width:"100%"}}
               src={props.opS5Img} alt={props.opS5ImgAlt} />
            </Col>
            <Col lg={6} md={6} className='timeline-item-text'>
              <p className='timeline-text'>{props.opS5Text}</p>
            </Col>
          </Row>
        </div>

        <div className='timeline-item'>
          <div className="timeline-dot">
            <FaCircle className='dot-middle' />
          </div>
          <Row>
            <Col xl={{ span: 6, order: 0 }} md={{ span: 6, order: 0 }}
              sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}
              className='timeline-item-text'>
              <p className='timeline-text'>{props.opS6Text}</p>
            </Col>
            <Col xl={{ span: 6, order: 1 }} md={{ span: 6, order: 1 }}
              sm={{ span: 12, order: 0 }} xs={{ span: 12, order: 0 }}
              className='timeline-item-image'>
              <h2 className='right-title'>{props.opS6Title}</h2>
              <img style={{height:"100%", width:"100%"}}
               src={props.opS6Img} alt={props.opS6ImgAlt} />
            </Col>
          </Row>
        </div>

        <div className='timeline-item'>
          <div className="timeline-dot">
            <FaCircle className='dot-middle' />
          </div>
          <Row>
            <Col lg={6} md={6} className='timeline-item-image'>
              <h2 className='left-title'>{props.opS7Title}</h2>
              <img style={{height:"100%", width:"100%"}}
               src={props.opS7Img} alt={props.opS7ImgAlt} />
            </Col>
            <Col lg={6} md={6} className='timeline-item-text'>
              <p className='timeline-text'>{props.opS7Text}</p>
            </Col>
          </Row>
        </div>

        <div className='timeline-item'>
          <div className="timeline-dot">
            <FaCircle className='dot-middle' />
          </div>
          <Row>
            <Col xl={{ span: 6, order: 0 }} md={{ span: 6, order: 0 }}
              sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}
              className='timeline-item-text'>
              <p className='timeline-text'>{props.opS8Text}</p>
            </Col>
            <Col xl={{ span: 6, order: 1 }} md={{ span: 6, order: 1 }}
              sm={{ span: 12, order: 0 }} xs={{ span: 12, order: 0 }}
              className='timeline-item-image'>
              <h2 className='right-title'>{props.opS8Title}</h2>
              <img style={{height:"100%", width:"100%"}}
               src={props.opS8Img} alt={props.opS8ImgAlt} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default OurProcess;
