import React from 'react';
import { Helmet } from 'react-helmet-async';
import ServicesHerobg from "./ServicesHero/ServicesHerobg";
import serviceslist from "../../json/serviceslist";
import ServiceCard from '../ServicesCard';
const LazyPartnerLogo = React.lazy(() => import("../PartnerLogo"));
const LazyContactForm = React.lazy(() => import("../Contact"));

const service = serviceslist.find((item) => item.key === 10);

const ParentService = () => {
  return (
    <>
      <Helmet>
        <title>Our Services - Novela Films</title>
        <link rel="canonical" href={service.serviceLink} />
        {/* Meta tags */}
        <meta name="description" content={service.serviceDESC} />
        <meta name="keywords" content={service.serviceKeywords} />
        <meta property="og:title" content="Our Services - Novela Films" />
        <meta property="og:description" content={service.serviceDESC} />
        <meta property="og:image" content={service.serviceIMG} />
      </Helmet>
      
      <ServicesHerobg
       heroIMG={service.heroIMG}
       heroIMG_SM={service.heroIMG_SM}
       heroIMG_MD={service.heroIMG_MD}
       heroIMG_LG={service.heroIMG_LG}
       heroIMGDETAIL={service.heroIMGDETAIL}
       heroTITLE1={service.heroTITLE1}
       heroTITLE2={service.heroTITLE2}
       heroVideoUrl={service.heroVIDEOURL}
       googleIMG={service.googleIMG}
       
       />
      
      <div style={{margin:"5% 0"}}>
        <h2 className="section-heading">Our Services</h2>
        <ServiceCard
        serviceCardImg="service-parent-page-img" />
      </div>
      <LazyPartnerLogo />
      <LazyContactForm />
    </>
    
  )
}

export default ParentService