import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ContactForm from "../components/Contact";
import Loading from "../components/Loading";
import DarkNavigationBar from "../components/DarkNavigation/DarkNavigationBar";
import Action from "../components/Action";
import Footer from "../components/Footer";
import PartnerLogo from "../components/PartnerLogo"

function Contactpage() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    })
    return (    
        <>
        {isLoading===true?
            <Loading />:
            <>
            <Helmet>
            <title>Contact Us - Novela Films</title>
            <link rel="canonical" href="https://novelafilms.com/contact/" />
            {/* Meta tags */}
            <meta name="description" content="Join us to explore limitless possibilities in visual storytelling. Let's create together and leave a mark on the world." />
            <meta name="keywords" content="Contact Us, Novela Films" />
            <meta property="og:title" content="Contact Us - Novela Films" />
            <meta property="og:description" content="Join us to explore limitless possibilities in visual storytelling. Let's create together and leave a mark on the world." />
            <meta property="og:image" content="https://novelafilms.com/novela-films.png" />
            </Helmet>
            <DarkNavigationBar />
            <ContactForm />
            <PartnerLogo />
            <br/>
            <Action />
            <Footer />
            </>
        }
        </>
       
    )
}

export default Contactpage