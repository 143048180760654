import React from 'react';
import { Image } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import "./ImageModal.css";

const VideoModal = (props) => {
  return (
    <div className='image-modal'>
            <div className='image-modal-items'>
              <Image
                src={props.imageSrc}
                height="100%"
                width="100%"
              />
            </div>
            <div className="image-modal-close" onClick={props.closeButton}>
              <FaTimes className="image-modal-close-icon" />
            </div>
    </div>
  )
}

export default VideoModal